<template>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </component>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useCommonData } from '@composables';
import { computed } from 'vue';
import DefaultLayout from '@components/layout/DefaultLayout.vue';
const route = useRoute();
const layout = computed(() => route.meta.layout || DefaultLayout);
useCommonData();
</script>

<style scoped lang="scss"></style>
