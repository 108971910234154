<template>
  <div class="terms">
    <h1>[필수] 개인정보 수집 및 이용동의</h1>
    <div class="scroll">
      <table class="terms-table">
        <colgroup>
          <col width="33.3%" span="3" />
        </colgroup>
        <tbody>
          <tr>
            <th>수집 및 이용목적</th>
            <th>수집하는<span>개인정보 항목</span></th>
            <th>보유 및 이용기간</th>
          </tr>
          <tr>
            <td>이용자 식별 및 본인여부 확인</td>
            <td>아이디, 비밀번호, 성명, 생년월일</td>
            <td>가입 시 선택</td>
          </tr>
          <tr>
            <td>
              계약이행을 위한 연락민원등 고객고충처리<br />(골프장 예약,취소
              확인 및 고객 관리등)
            </td>
            <td>연락처(이메일, 휴대전화번호)</td>
            <td>가입 시 선택</td>
          </tr>
          <tr>
            <td>만 14세 미만 아동인지 확인</td>
            <td>법정생년월일</td>
            <td>가입 시 선택</td>
          </tr>
        </tbody>
      </table>
      ※ 예약 서비스 제공을 위해서 필요한 최소한의 개인정보이므로 동의를 해
      주셔야 서비스를 이용하실수 있습니다.<br /><br />‘본 동의는 본질적 기능
      제공을 위한 개인정보 수집/이용에 대한 동의로서, 고객님은 개인정보 수집 및
      이용에 대한 동의를 거부할 권리가 있으며, 본 동의를 거부할 경우 받는 별도의
      불이익은 없습니다.<br />단, 본 동의를 거부할 경우, 서비스 이용이
      불가능합니다.’
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss">
/*팝업*/
.terms {
  width: 1104px;
  height: 580px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #ccc;
  padding: 56px 62px;
}
.terms h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 40px;
}
.terms .scroll {
  overflow-y: auto;
  height: 445px;
  font-size: 1rem;
  font-weight: 300;
  padding-right: 5px;
  line-height: 1.13rem;
  padding-bottom: 56px;
}

/*팝업 테이블*/
.terms-table {
  width: 100%;
  border-top: 2px solid #222;
  border-bottom: 1px double #222;
  text-align: center;
  font-size: 1.13rem;
  margin-bottom: 1rem;
}
.terms-table tbody th {
  font-size: 1rem;
  font-weight: 700;
  color: #666;
  background: #f9f9f9;
  border-right: 1px solid #ccc;
  padding: 18px 0;
}
.terms-table tbody th:last-child {
  border-right: 0;
}
.terms-table tbody td {
  padding: 21px 0;
  font-weight: 300;
  border: 1px solid #ccc;
  line-height: 18px;
}
.terms-table tbody td:first-child {
  border-left: 0;
}
.terms-table tbody td:last-child {
  border-right: 0;
}

/*팝업 스크롤바*/
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #ccc;
}
::-webkit-scrollbar-thumb {
  background-color: #eb7617;
}

/*팝업 닫기 버튼*/
.modal-footer button {
  background: rgba(255, 255, 255, 0.11);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
.modal-footer button img {
  width: 24px;
  height: 24px;
}

/*모바일*/
@media (min-width: 320px) and (max-width: 1279px) {
  .terms {
    width: 100% !important;
    padding: 16px 10px 16px 16px !important;
    height: 400px !important;
  }

  .terms h1 {
    font-size: 1.13rem;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .terms .scroll {
    height: 85% !important;
    overflow-y: auto;
    font-size: 0.81rem;
    font-weight: 300;
    line-height: 1.13rem;
    padding-bottom: 20px;
  }

  /*테이블*/
  .terms-table {
    font-size: 0.81rem;
  }
  .terms-table tbody th {
    font-size: 0.81rem;
    padding: 4px 0;
  }
  .terms-table tbody th span {
    display: block;
  }
  .terms-table tbody td {
    padding: 4px 0;
  }
  .terms-table tbody td span {
    display: block;
  }

  /*팝업 닫기 버튼*/
  .modal-footer button {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
  .modal-footer button img {
    width: 16px;
    height: 16px;
  }
}
</style>
