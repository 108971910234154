import { ROUTE_NAME } from '@constants';
const courseIntroduction = {
  path: '/course-introduction',
  redirect: '/course-introduction/strategy',
  name: ROUTE_NAME.CourseInformation,
  meta: {
    breadcrumb: true,
    breadcrumbName: '코스안내',
  },
  children: [
    {
      name: ROUTE_NAME.CourseStrategy,
      path: 'strategy',
      component: () => import('@views/course-introduction/CourseStrategy.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '코스공략',
      },
    },
  ],
};

export default courseIntroduction;
