import { ref, reactive, computed, onMounted } from 'vue';
import { AxiosAdapter, UserAdapter } from '@adapters';
import {
  FetchUserDetailUseCase,
  FetchUserInfoUseCase,
  UpdateUserInfoUseCase,
} from '@core/useCases';
import { useAlertStore, useUserStore } from '@stores';
import {
  DORMANT_ACCOUNT_CONVERSION_CODE,
  GENDER_CODE,
  LUNAR_CODE,
  STATUS_YN,
} from '@core/enums';
import {
  getBirthdayWithHyphen,
  isBirthday,
  isMobileNumber,
  isPwd,
} from '@core/utils';
import { ROUTE_NAME } from '@constants';
import { useRouter } from 'vue-router';

interface UserInfo {
  [key: string]: string | boolean;
  memberName: string;
  hpNo: string;
  webId: string;
  currentPassword: string;
  newPassword: string;
  birthDay: string;
  sexCode: string;
  lunarCode: string;
  areaCode: string;
  sigunCode: string;
  email: string | '';
  emailRecptnAgre: boolean;
  smsRecptnDiv: string;
  marktInfoAgreFlag: boolean;
  drmncyCnvrsCode: string;
  homePostNo: string | '';
  homeAddr1: string | '';
  homeAddr2: string | '';
}

const genderOptions = [
  { value: GENDER_CODE.MALE, label: '남성' },
  { value: GENDER_CODE.FEMAIL, label: '여성' },
];
const lunarOptions = [
  { value: LUNAR_CODE.SOLAR, label: '양력' },
  { value: LUNAR_CODE.LUNAR, label: '음력' },
];
const smsAgreeOptions = [
  {
    value: STATUS_YN.YES,
    label: '수신',
  },
  {
    value: STATUS_YN.NO,
    label: '수신안함',
  },
];
const marketingAgreeOptions = [
  { value: true, label: '동의함' },
  { value: false, label: '동의안함' },
];
const personalInfoExpiryOptions = [
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.YEAR, label: '1년' },
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT, label: '탈퇴시까지' },
];

export const useUserModify = () => {
  const axiosAdapter = new AxiosAdapter();
  const userAdapter = new UserAdapter(axiosAdapter);
  const alertStore = useAlertStore();
  const userStore = useUserStore();
  const router = useRouter();

  const newPasswordConfirm = ref('');
  const defaultPhone = ref<string>();
  const originData = ref();
  const userInfo = reactive<UserInfo>({
    memberName: userStore.memberName,
    hpNo: userStore.hpNo,
    webId: '',
    currentPassword: '',
    newPassword: '',
    birthDay: '',
    sexCode: '',
    lunarCode: LUNAR_CODE.SOLAR,
    areaCode: '',
    sigunCode: '',
    email: '',
    emailRecptnAgre: true,
    smsRecptnDiv: userStore.isPrivacyUseAgreement
      ? STATUS_YN.YES
      : STATUS_YN.NO,
    marktInfoAgreFlag: true,
    drmncyCnvrsCode: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT,
    homePostNo: '',
    homeAddr1: '',
    homeAddr2: '',
  });

  const isPasswordMatch = computed(() => {
    return userInfo.newPassword === newPasswordConfirm.value;
  });

  // 회원정보 수정여부 판별
  const _getChangedKeys = <T extends object>(
    original: T,
    current: T
  ): (keyof T)[] => {
    const changedKeys: (keyof T)[] = [];

    for (const key in original) {
      if (Object.hasOwn(original, key) && Object.hasOwn(current, key)) {
        if (original[key] !== current[key]) {
          changedKeys.push(key);
        }
      }
    }

    return changedKeys;
  };
  const _hasChanges = <T extends object>(original: T, current: T): boolean => {
    const changedKeys = _getChangedKeys(original, current);
    return changedKeys.length > 0;
  };

  const _getTargetUserInfo = (originData: UserInfo) => {
    const keysToCopy = [
      'newPassword',
      'sexCode',
      'areaCode',
      'sigunCode',
      'email',
      'emailRecptnAgre',
      'birthDay',
      'lunarCode',
      'hpNo',
      'smsRecptnDiv',
      'drmncyCnvrsCode',
      'homePostNo',
      'homeAddr1',
      'homeAddr2',
    ];
    return Object.keys(originData).reduce((targetUserInfo, key) => {
      if (keysToCopy.includes(key)) {
        targetUserInfo[key] = originData[key];
      }
      return targetUserInfo;
    }, {} as UserInfo);
  };

  const validateForm = async (
    isVerifyComplete: boolean,
    hpNoCertificationToken: string
  ) => {
    const {
      currentPassword,
      newPassword,
      // areaCode,
      // sigunCode,
      birthDay,
      hpNo,
      homePostNo,
      homeAddr1,
    } = userInfo;

    const targetUserInfo = _getTargetUserInfo(originData.value);

    if (!currentPassword) {
      await alertStore.showAlertDialog('현재 비밀번호를 입력해주세요');
      return;
    }
    if (!_hasChanges(targetUserInfo, userInfo)) {
      await alertStore.showAlertDialog('변경된 사항이 없습니다');
      return;
    }
    if (newPassword) {
      if (!isPwd({ pwd: newPassword, type: 'B', min: 8, max: 12 })) {
        await alertStore.showAlertDialog(
          '비밀번호는 영어/숫자/특수문자를 조합하여 8~12자 이내로 입력해주세요.'
        );
        return;
      }
      if (!isPasswordMatch.value) {
        await alertStore.showAlertDialog(
          '새로운 비밀번호를 확인해주시기 바랍니다'
        );
        return;
      }
    }
    if (!homePostNo || !homeAddr1) {
      await alertStore.showAlertDialog('주소를 입력해주세요.');
      return;
    }
    if (!isBirthday(birthDay)) {
      await alertStore.showAlertDialog('생년월일을 확인해주세요.');
      return;
    }
    if (defaultPhone.value !== hpNo && !hpNoCertificationToken) {
      await alertStore.showAlertDialog('새로운 핸드폰 번호입니다 인증해주세요');
      return;
    }
    if (hpNoCertificationToken) {
      if (!hpNo || !isMobileNumber(hpNo)) {
        await alertStore.showAlertDialog('휴대폰 번호를 확인해주세요.');
        return;
      }
      if (!isVerifyComplete) {
        await alertStore.showAlertDialog('본인인증을 진행해주세요.');
        return;
      }
    }
    return true;
  };

  const handleModify = async (
    isVerifyComplete: boolean,
    hpNoCertificationToken: string
  ) => {
    const isValid = await validateForm(
      isVerifyComplete,
      hpNoCertificationToken
    );
    if (!isValid) {
      return;
    }
    const updateUserInfoUseCase = new UpdateUserInfoUseCase(userAdapter);

    const {
      currentPassword,
      birthDay,
      sexCode,
      lunarCode,
      smsRecptnDiv,
      drmncyCnvrsCode,
      newPassword,
      areaCode,
      sigunCode,
      hpNo,
      marktInfoAgreFlag,
      email,
      emailRecptnAgre,
      homePostNo,
      homeAddr1,
      homeAddr2,
    } = userInfo;

    try {
      await updateUserInfoUseCase.execute({
        currentPassword,
        hpNoCertificationToken,
        tgWebId: {
          webPwd: newPassword ? newPassword : null,
          tgMember: {
            birthDay: getBirthdayWithHyphen(birthDay),
            lunarCode,
            sexCode,
            hpNo: isVerifyComplete ? hpNo : null,
            smsRecptnDiv,
            tgMemberAddi: {
              drmncyCnvrsCode,
              areaCode,
              sigunCode,
              marktInfoAgreFlag,
              email,
              emailRecptnAgre,
              homePostNo,
              homeAddr1,
              homeAddr2,
            },
          },
        },
      });

      await alertStore.showAlertDialog('수정되었습니다');
      await router.push({ name: ROUTE_NAME.Main });
    } catch (error) {
      console.log(error);
    }
  };

  onMounted(async () => {
    const fetchUserDetailUseCase = new FetchUserDetailUseCase(userAdapter);
    const fetchUserInfoUseCase = new FetchUserInfoUseCase(userAdapter);

    const { phone } = await fetchUserDetailUseCase.execute();
    defaultPhone.value = phone;
    userInfo.hpNo = phone;

    const data = await fetchUserInfoUseCase.execute();
    userInfo.memberName = data.name;
    userInfo.webId = data.webId;
    userInfo.birthDay = data.tgMember.birthDay.split('-').join('');
    userInfo.sexCode = data.tgMember.sexCode;
    userInfo.lunarCode = data.tgMember.lunarCode;
    userInfo.areaCode = data.tgMember.tgMemberAddi.areaCode;
    userInfo.sigunCode = data.tgMember.tgMemberAddi.sigunCode;
    userInfo.email = data.tgMember.tgMemberAddi.email || '';
    userInfo.emailRecptnAgre = data.tgMember.tgMemberAddi.emailRecptnAgre;
    userInfo.drmncyCnvrsCode = data.tgMember.tgMemberAddi.drmncyCnvrsCode;
    userInfo.marktInfoAgreFlag = data.tgMember.tgMemberAddi.marktInfoAgreFlag;
    userInfo.smsRecptnDiv = data.tgMember.smsRecptnDiv;
    userInfo.homePostNo = data.tgMember.tgMemberAddi.homePostNo || '';
    userInfo.homeAddr1 = data.tgMember.tgMemberAddi.homeAddr1 || '';
    userInfo.homeAddr2 = data.tgMember.tgMemberAddi.homeAddr2 || '';

    // originData.value = JSON.parse(JSON.stringify(userInfo));
    originData.value = { ...userInfo };
  });

  return {
    userInfo,
    genderOptions,
    lunarOptions,
    smsAgreeOptions,
    marketingAgreeOptions,
    personalInfoExpiryOptions,
    newPasswordConfirm,
    isPasswordMatch,
    handleModify,
  };
};
