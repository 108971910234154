export const ReservationApiEndpoint = {
  // 실시간 예약
  requestReservation: '/user-reservations', // POST: 예약 등록
  fetchReservationList: '/user-reservations', // GET: 예약 현황 조회
  fetchReservationDetail: '/user-reservations/:reservationId', // GET: 예약 상세 조회
  cancelReservation: '/reservations/:reservationId', // DELETE: 예약 취소
  updateReservation: '/reservations/:reservationId', // PUT: 예약 변경
  fetchReservableStatus: '/user-reservations/reservable/:timeId', // GET: 예약 가능 여부 조회
  fetchReservationPenalty: '/reservations/:reservationId/penalty', //GET: 예약 패널티 조회
  fetchPenaltyList: '/user-reservations/penalties', //GET: 사용자 위약 현황 조회

  // 대기 예약
  requestWaitingReservation: '/user-reservations/waiting', // POST: 대기 예약 등록
  fetchWaitingReservationList: '/user-reservations/waiting', // GET: 대기 예약 현황 조회
  cancelWaitingReservation: '/waiting-reservations/:waitingId', // DELETE: 대기 예약 취소

  // 예약 달력 조회
  fetchCalendarReservationList: '/reservation-calender', // GET: 실시간 예약 달력 조회
  fetchCalendarWaitingReservationList: '/reservation-calender/waiting', // GET: 대기 예약 달력 조회
  fetchCalendarReservationDetail: '/reservation-calender/:bsnDate', // GET: 예약 변경 가능한 리스트

  // 예약 동반자
  requestCompanion: '/reservations/:reservationId/companions', // POST: 예약 동반자 등록
  requestCompanionList: '/reservations/:reservationId/companions/list', // POST: 예약 동반자 일괄등록
  deleteCompanion: '/reservations/:reservationId/companions/:sno', // DELETE: 예약 동반자 삭제

  //단체
  group: '/group',
  deleteGroup: '/group/:grpRequestId',
  groupFindId: '/group/find-id', //POST: 회원조회
  fetchGroupReservation: '/user-reservations/group/:resveNo', //단체 조편성 조회
  requestGroupFormation: '/reservations/:resveNo/companions/group', //단체 조편성 신청

  //추첨예약
  requestLotteryReservation: '/user-reservations/lottery', //추첨예약 현황 등록
  fetchLotteryReservation: '/user-reservations/lottery', //추첨예약 현황 조회
  deleteLotteryReservation: '/lottery-reservations/:lotteryId', //추첨(주말) 예약 취소
};
