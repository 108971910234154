import { defineStore, DefineStoreOptions } from 'pinia';
import { AxiosResponse } from 'axios';

interface SettingStoreState {
  captchaUseFlag: boolean;
}
interface SettingStoreGetters {
  isCaptchaUseFlag: (state: SettingStoreState) => boolean;
}

interface SettingStoreActions {
  setPreferences: (header: AxiosResponse['headers']) => void;
}

type SettingStoreOptions = DefineStoreOptions<
  string,
  SettingStoreState,
  SettingStoreGetters,
  SettingStoreActions
>;

const settingStoreId = 'SettingStore';

const settingStoreOptions: SettingStoreOptions = {
  id: settingStoreId,
  state: () => ({
    captchaUseFlag: false,
  }),
  getters: {
    isCaptchaUseFlag: ({ captchaUseFlag }) => {
      return captchaUseFlag;
    },
  },
  actions: {
    setPreferences(headers: AxiosResponse['headers']) {
      this.captchaUseFlag = headers['x-captcha-use-flag'] === 'true';
    },
  },
};

export const useSettingStore = defineStore(settingStoreId, settingStoreOptions);
