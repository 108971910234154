<template>
  <ABackDrop :isVisible="isVisible">
    <div class="dialog">
      <slot name="content"></slot>
      <div class="actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </ABackDrop>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { ABackDrop } from '@atoms';

const props = defineProps({
  isVisible: Boolean,
});

const isVisible = toRef(props, 'isVisible');
</script>

<style scoped lang="scss">
.dialog {
  @apply bg-white p-20 rounded-2xl;
  padding: 32px 0;
}
</style>
