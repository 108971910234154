import { ref, onMounted, onUnmounted } from 'vue';

export function useScroll(threshold = 200) {
  const isScroll = ref(false);

  const handleScroll = () => {
    isScroll.value = window.scrollY > threshold;
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  return {
    isScroll,
  };
}
