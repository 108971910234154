<template>
  <div class="wrapper">
    <MobileHeader v-if="commonStore.isMobile" ref="header" class="header" />
    <DesktopHeader v-if="commonStore.isDesktop" ref="header" class="header" />
    <main>
      <div ref="container">
        <!--        <MBreadCrumb />-->
        <router-view />
      </div>
    </main>
    <MobileFooter v-if="commonStore.isMobile" ref="footer" class="footer" />
    <DesktopFooter v-if="commonStore.isDesktop" ref="footer" class="footer" />
    <MAlertDialog />
    <MConfirmDialog />
    <MModal ref="modal" @close="handleCloseModal">
      <template #modal-body>
        <Signin @signIn="handleSignInComplete" />
      </template>
    </MModal>
    <MQuickMenu />
  </div>
</template>
<script setup lang="ts">
import MobileHeader from '@components/common/MobileHeader.vue';
import MobileFooter from '@components/common/MobileFooter.vue';
import DesktopHeader from '@components/common/DesktopHeader.vue';
import DesktopFooter from '@components/common/DesktopFooter.vue';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useCommonStore } from '@stores';
import { MAlertDialog, MConfirmDialog, MModal, MQuickMenu } from '@molecules';
import Signin from '@views/auth/Signin.vue';

// const header = ref<HTMLElement>();
// const footer = ref<HTMLElement>();
const mobileHeader = ref<HTMLElement | null>(null);
const desktopHeader = ref<HTMLElement | null>(null);
const mobileFooter = ref<HTMLElement | null>(null);
const desktopFooter = ref<HTMLElement | null>(null);
const container = ref<HTMLElement>();
const commonStore = useCommonStore();
const modal = ref();

const handleSignInComplete = (isComplete: boolean) => {
  if (isComplete) {
    modal.value.close();
  }
};
const adjustContainerHeight = async () => {
  if (!container.value) return;
  // const headerHeight = header.value?.offsetHeight || 0;
  // const footerHeight = footer.value?.offsetHeight || 0;
  const headerHeight = commonStore.isMobile
    ? mobileHeader.value?.offsetHeight || 0
    : desktopHeader.value?.offsetHeight || 0;

  const footerHeight = commonStore.isMobile
    ? mobileFooter.value?.offsetHeight || 0
    : desktopFooter.value?.offsetHeight || 0;

  container.value.style.minHeight = `calc(100vh - ${headerHeight + footerHeight}px)`;
};

onMounted(() => {
  adjustContainerHeight();
  window.addEventListener('resize', adjustContainerHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustContainerHeight);
});

const handleCloseModal = () => {
  commonStore.setSigninOpen(false);
};
watch(
  () => commonStore.isSigninOpen,
  (isSigninOpen: boolean) => {
    if (isSigninOpen && modal.value) {
      modal.value.open();
    }
    if (!isSigninOpen && modal.value) {
      modal.value.close();
    }
  },
  {
    immediate: true,
  }
);
</script>

<style scoped lang="scss">
.menu-size {
  @apply pt-72;
  @apply desktop:pt-170;
}
main {
  padding-bottom: env(safe-area-inset-bottom);
  @apply bg-white;
}
.wrapper {
  position: relative;
  .container {
    @apply desktop:w-[1200px] desktop:m-auto;
  }
}
</style>
