import { AxiosAdapter, UserAdapter } from '@adapters';
import { CheckIdDuplicateUsecase } from '@core/useCases';
import { useAlertStore } from '@stores';

export const useCheckIdDuplicate = () => {
  const axiosAdapter = new AxiosAdapter();
  const userAdapter = new UserAdapter(axiosAdapter);
  const checkIdDuplicateUsecase = new CheckIdDuplicateUsecase(userAdapter);
  const alertStore = useAlertStore();

  const handleCheckIdDuplicate = async (webId: string) => {
    if (webId === '') {
      await alertStore.showAlertDialog('아이디를 입력해주세요');
      return false;
    }
    if (webId.length < 4 || webId.length > 12) {
      await alertStore.showAlertDialog(
        '아이디는 4자 이상 12자 이하로 입력해주세요'
      );
      return false;
    }
    return await checkIdDuplicateUsecase.execute(webId);
  };

  return {
    handleCheckIdDuplicate,
  };
};
