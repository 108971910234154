import { ROUTE_NAME } from '@constants';
const reservation = {
  name: ROUTE_NAME.Reservation,
  path: '/reservation',
  redirect: '/reservation/reservation',
  meta: {
    breadcrumb: true,
    breadcrumbName: '예약서비스',
  },
  children: [
    {
      name: ROUTE_NAME.Reservation,
      path: 'reservation',
      component: () => import('@views/reservation/Reservation.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '예약하기',
      },
    },
    {
      name: ROUTE_NAME.ReservationInformation,
      path: 'information',
      component: () => import('@views/reservation/ReservationInformation.vue'),
      meta: {
        requiresAuth: false,
        breadcrumb: true,
        breadcrumbName: '예약안내',
      },
    },
    {
      name: ROUTE_NAME.ReservationStatus,
      path: 'status',
      component: () => import('@views/reservation/ReservationStatus.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: true,
        breadcrumbName: '예약확인',
      },
    },
    {
      name: ROUTE_NAME.ReservationPayment,
      path: 'payment',
      component: () => import('@views/reservation/ReservationPayment.vue'),
      meta: {
        requiresAuth: true,
        breadcrumb: false,
        breadcrumbName: '결제하기',
      },
    },
  ],
};

export default reservation;
