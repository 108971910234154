import { defineStore, DefineStoreOptions } from 'pinia';
// import { UserModel } from '@models';
import { useCommonStore } from '@stores';
import { jwtDecode } from 'jwt-decode';
import { SignUpResponse } from '@dto';
interface UserStoreState {
  // userInfo: UserModel;
  accessToken: string | null;
  isPrivacyUseAgreement: boolean;
  isPhoneCertification: boolean;
  userInfo: SignUpResponse | null;
  memberName: string;
  hpNo: string;
  isMarketingAgree: boolean;
}
export interface HomepageAPIStoreState {
  accessToken: string | null;
}
interface UserStoreGetters {
  // getUserInfo(): UserModel;
  userId: (state: HomepageAPIStoreState) => string | null | undefined;
  username: (state: HomepageAPIStoreState) => string | null | undefined;
  marketingAgree: (state: UserStoreState) => boolean;
}
interface UserStoreActions {
  // setSignupAgree(isAgree: boolean): void;
  setPhoneCertification(isCertification: boolean): void;
  setUserInfo(userInfo: SignUpResponse): void;
  // setSignupPhase(phase: number): void;
  setMemberName(userName: string): void;
  setHpNo(phone: string): void;
  setPrivacyUseAgreement(isAgree: boolean): void;
  resetState(): void;
  resetUser(): void;
  setUser(token: string | null): void;
  setMarketingAgree(isAgree: boolean): void;
}

interface AccessTokenDecoded {
  'com.fixelsoft.auth.jwt.auth.userId': string;
  'com.fixelsoft.auth.jwt.auth.username': string;
  'com.fixelsoft.auth.jwt.auth.grant': string;
  'com.fixelsoft.auth.jwt.auth.webId'?: string;
  'com.fixelsoft.auth.jwt.auth.memberId'?: string;
  'com.fixelsoft.auth.jwt.auth.memberName'?: string;
}

type UserStoreOptions = DefineStoreOptions<
  string,
  UserStoreState,
  UserStoreGetters,
  UserStoreActions
>;

const getAccessToken = (): string | null => {
  const commonStore = useCommonStore();
  return commonStore.getSessionStorage('token');
};

const userStoreId = 'user';
const userStoreOptions: UserStoreOptions = {
  id: userStoreId,
  state() {
    return {
      // userInfo: new UserModel({ id: '', name: '' }),
      accessToken: null,
      isPrivacyUseAgreement: false,
      isPhoneCertification: false,
      userInfo: null,
      // signupPhase: 0,
      memberName: '',
      hpNo: '',
      isMarketingAgree: false,
    };
  },
  getters: {
    userId: ({ accessToken }) => {
      const token = accessToken || getAccessToken();
      if (token) {
        return (
          jwtDecode<AccessTokenDecoded>(token)[
            'com.fixelsoft.auth.jwt.auth.userId'
          ] || null
        );
      } else {
        return null;
      }
    },
    username: ({ accessToken }) => {
      const token = accessToken || getAccessToken();
      if (token) {
        return (
          jwtDecode<AccessTokenDecoded>(token)[
            'com.fixelsoft.auth.jwt.auth.username'
          ] || null
        );
      } else {
        return null;
      }
    },
    grant: ({ accessToken }) => {
      if (accessToken || sessionStorage.getItem('token')) {
        return (
          jwtDecode<AccessTokenDecoded>(
            accessToken || (sessionStorage.getItem('token') as string)
          )['com.fixelsoft.auth.jwt.auth.grant'] || null
        );
      } else {
        return null;
      }
    },
    marketingAgree: ({ isMarketingAgree }) => isMarketingAgree,
  },
  actions: {
    setPrivacyUseAgreement(isAgree: boolean) {
      this.isPrivacyUseAgreement = isAgree;
    },
    setPhoneCertification(isCertification: boolean) {
      this.isPhoneCertification = isCertification;
    },
    setUserInfo(userInfo: SignUpResponse) {
      this.userInfo = userInfo;
    },
    setMemberName(memberName: string) {
      this.memberName = memberName;
    },
    setHpNo(hpNo: string) {
      this.hpNo = hpNo;
    },
    resetState() {
      this.isPrivacyUseAgreement = false;
      this.isPhoneCertification = false;
    },
    resetUser() {
      this.accessToken = null;
    },
    setUser(token) {
      this.accessToken = token;
    },
    setMarketingAgree(isAgree: boolean) {
      this.isMarketingAgree = isAgree;
    },
  },
};

export const useUserStore = defineStore(userStoreId, userStoreOptions);
