import { onMounted } from 'vue';
import { AxiosAdapter, CommonAdapter } from '@adapters';
import { FetchCommonTotalUseCase } from '@core/useCases';
import { useCommonStore } from '@stores';

export const useCommonData = () => {
  const axiosAdapter = new AxiosAdapter();
  const commonAdapter = new CommonAdapter(axiosAdapter);
  const fetchCommonTotalUseCase = new FetchCommonTotalUseCase(commonAdapter);
  const commonStore = useCommonStore();

  onMounted(async () => {
    if (!commonStore.isTotalSystemInfo) {
      const data = await fetchCommonTotalUseCase.execute();
      if (data) {
        commonStore.setTotalSystemInfo(data);
      }
    }
  });
};
