import { SignInAdminPayload } from '@core/dto';
import { AuthModel } from '@core/models';

export class SignInAdminUseCase {
  constructor(private readonly authAdapter: any) {
    this.authAdapter = authAdapter;
  }
  async execute(payload: SignInAdminPayload): Promise<AuthModel> {
    // try {
    return await this.authAdapter.signInAdmin(payload);
    // } catch (error) {
    // AuthModel.createEmpty();
    //   throw error;
    // }
  }
}
