import { ReservationPort } from '@core/ports';
import { ReservationApiEndpoint } from '@core/api-endpoint';
import {
  CancelReservationPayload,
  CancelWaitingReservationPayload,
  DeleteCompanionPayload,
  FetchCalendarReservationDetailPayload,
  FetchCalendarReservationDetailResponse,
  FetchCalendarReservationListPayload,
  FetchCalendarReservationListResponse,
  FetchCalendarWaitingReservationListPayload,
  FetchCalendarWaitingReservationListResponse,
  FetchGroupFormationPayload,
  FetchGroupFormationResponse,
  FetchReservableStatusPayload,
  FetchReservationDetailPayload,
  FetchReservationDetailResponse,
  FetchReservationListPayload,
  FetchReservationListResponse,
  FetchReservationPenaltyPayload,
  FetchReservationPenaltyResponse,
  FetchWaitingReservationListPayload,
  FetchWaitingReservationListResponse,
  FindIdPayload,
  RequestCompanionPayload,
  RequestGroupFormationPayload,
  RequestReservationPayload,
  RequestReservationResponse,
  RequestWaitingReservationPayload,
  FetchGroupApplicationListPayload,
  FetchGroupApplicationListResponse,
  UpdateReservationPayload,
  FetchPenaltyListResponse,
  FetchPenaltyListPayload,
  RequestCompanionListPayload,
  FetchLotteryReservationListPayload,
  FetchLotteryReservationListResponse,
  RequestLotteryReservationListPayload,
} from '@core/dto';
import { HttpInterface } from '@core/http';
export class ReservationAdapter
  implements
    ReservationPort<
      any,
      | RequestReservationPayload
      | FetchReservationListPayload
      | FetchReservationDetailPayload
      | CancelReservationPayload
      | UpdateReservationPayload
      | RequestWaitingReservationPayload
      | FetchWaitingReservationListPayload
      | CancelWaitingReservationPayload
      | FetchCalendarReservationListPayload
      | FetchCalendarReservationDetailPayload
      | RequestCompanionPayload
      | DeleteCompanionPayload
      | FormData
      | FindIdPayload
      | FetchReservableStatusPayload
      | FetchGroupFormationPayload
      | RequestGroupFormationPayload
      | FetchGroupApplicationListPayload
      | FetchPenaltyListPayload
      | FetchLotteryReservationListPayload
      | RequestLotteryReservationListPayload
    >
{
  http: HttpInterface;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
  }

  // 실시간 예약
  async requestReservation(
    payload: RequestReservationPayload
  ): Promise<RequestReservationResponse> {
    const response = await this.http.post<RequestReservationResponse>(
      ReservationApiEndpoint.requestReservation,
      payload
    );
    return response.data;
  }

  async fetchReservationList(
    payload: FetchReservationListPayload
  ): Promise<FetchReservationListResponse> {
    const response = await this.http.get<FetchReservationListResponse>(
      ReservationApiEndpoint.fetchReservationList,
      payload
    );
    return response.data;
  }
  async fetchReservationDetail(
    payload: FetchReservationDetailPayload
  ): Promise<FetchReservationDetailResponse> {
    const { reservationId } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.fetchReservationDetail,
      { reservationId }
    );
    const response = await this.http.get<FetchReservationDetailResponse>(url);
    return response.data;
  }
  async fetchPenaltyList(payload: FetchPenaltyListPayload) {
    const response = await this.http.get<FetchPenaltyListResponse>(
      ReservationApiEndpoint.fetchPenaltyList,
      payload
    );
    return response.data;
  }
  async cancelReservation(payload: CancelReservationPayload): Promise<void> {
    const { reservationId } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.cancelReservation,
      {
        reservationId,
      }
    );
    await this.http.delete<void>(url);
  }
  async updateReservation(payload: UpdateReservationPayload): Promise<void> {
    const { reservationId, timeId } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.updateReservation,
      {
        reservationId,
      }
    );
    await this.http.put<void>(url, { timeId });
  }

  // 대기 예약
  async requestWaitingReservation(
    payload: RequestWaitingReservationPayload
  ): Promise<void> {
    await this.http.post<void>(
      ReservationApiEndpoint.requestWaitingReservation,
      payload
    );
  }
  async fetchWaitingReservationList(
    payload: FetchWaitingReservationListPayload
  ): Promise<FetchWaitingReservationListResponse> {
    const response = await this.http.get<FetchWaitingReservationListResponse>(
      ReservationApiEndpoint.fetchWaitingReservationList,
      payload
    );
    return response.data;
  }
  async cancelWaitingReservation(
    payload: CancelWaitingReservationPayload
  ): Promise<void> {
    const { waitingId } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.cancelWaitingReservation,
      {
        waitingId,
      }
    );
    await this.http.delete<void>(url);
  }

  // 예약 달력 조회
  async fetchCalendarReservationList(
    payload: FetchCalendarReservationListPayload
  ): Promise<FetchCalendarReservationListResponse> {
    const response = await this.http.get<FetchCalendarReservationListResponse>(
      ReservationApiEndpoint.fetchCalendarReservationList,
      payload
    );
    return response.data;
  }
  async fetchCalendarWaitingReservationList(
    payload: FetchCalendarWaitingReservationListPayload
  ): Promise<FetchCalendarWaitingReservationListResponse> {
    const response =
      await this.http.get<FetchCalendarWaitingReservationListResponse>(
        ReservationApiEndpoint.fetchCalendarWaitingReservationList,
        payload
      );
    return response.data;
  }
  async fetchCalendarReservationDetail(
    payload: FetchCalendarReservationDetailPayload
  ): Promise<FetchCalendarReservationDetailResponse> {
    const { bsnDate } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.fetchCalendarReservationDetail,
      { bsnDate }
    );
    const response =
      await this.http.get<FetchCalendarReservationDetailResponse>(url);
    return response.data;
  }
  // 예약 동반자
  async requestCompanion(payload: RequestCompanionPayload): Promise<void> {
    const { reservationId, cmpnName, sexCode, contactTel } = payload;
    const url = this.http.replacedUrl(ReservationApiEndpoint.requestCompanion, {
      reservationId,
    });
    await this.http.post<void>(url, { cmpnName, sexCode, contactTel });
  }
  async requestCompanionList(payload: {
    reservationId: number;
    cmpnList: RequestCompanionListPayload[];
  }): Promise<void> {
    const { reservationId, cmpnList } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.requestCompanionList,
      {
        reservationId,
      }
    );
    await this.http.post<void>(url, cmpnList);
  }

  async deleteCompanion(payload: DeleteCompanionPayload): Promise<void> {
    const { reservationId, sno } = payload;
    const url = this.http.replacedUrl(ReservationApiEndpoint.deleteCompanion, {
      reservationId,
      sno,
    });
    await this.http.delete<void>(url);
  }
  async fetchReservationPenalty(
    payload: FetchReservationPenaltyPayload
  ): Promise<FetchReservationPenaltyResponse> {
    const { reservationId } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.fetchReservationPenalty,
      {
        reservationId,
      }
    );
    const response = await this.http.get<FetchReservationPenaltyResponse>(url);
    return response.data;
  }
  async requestGroupMemberConfirm(
    payload: FindIdPayload
  ): Promise<{ webId: string }> {
    const response = await this.http.post<{ webId: string }>(
      ReservationApiEndpoint.groupFindId,
      payload
    );
    return response.data;
  }
  async requestFormGroupReservation(payload: FormData): Promise<void> {
    await this.http.post<void>(ReservationApiEndpoint.group, payload);
  }
  async fetchGroupApplication(
    payload: FetchGroupApplicationListPayload
  ): Promise<FetchGroupApplicationListResponse> {
    const response = await this.http.get<FetchGroupApplicationListResponse>(
      ReservationApiEndpoint.group,
      payload
    );
    return response.data;
  }
  async deleteGroupApplication(payload: {
    grpRequestId: number;
  }): Promise<void> {
    const { grpRequestId } = payload;
    const url = this.http.replacedUrl(ReservationApiEndpoint.deleteGroup, {
      grpRequestId,
    });
    await this.http.delete<void>(url);
  }
  async fetchReservableStatus(
    payload: FetchReservableStatusPayload
  ): Promise<boolean> {
    const { timeId } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.fetchReservableStatus,
      {
        timeId,
      }
    );
    const response = await this.http.get<boolean>(url);
    return response.data;
  }
  async fetchGroupFormation(
    payload: FetchGroupFormationPayload
  ): Promise<FetchGroupFormationResponse> {
    const { resveNo } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.fetchGroupReservation,
      {
        resveNo,
      }
    );
    const response = await this.http.get<FetchGroupFormationResponse>(url);
    return response.data;
  }
  async requestGroupFormation(
    payload: RequestGroupFormationPayload
  ): Promise<void> {
    const { resveNo, requestGroupFormation } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.requestGroupFormation,
      {
        resveNo,
      }
    );
    await this.http.post<void>(url, requestGroupFormation);
  }
  async requestLotteryReservation(
    payload: RequestLotteryReservationListPayload
  ): Promise<void> {
    await this.http.post<void>(
      ReservationApiEndpoint.requestLotteryReservation,
      payload
    );
  }
  async fetchLotteryReservationList(
    payload: FetchLotteryReservationListPayload
  ): Promise<FetchLotteryReservationListResponse> {
    const response = await this.http.get<FetchLotteryReservationListResponse>(
      ReservationApiEndpoint.fetchLotteryReservation,
      payload
    );
    return response.data;
  }
  async deleteLotteryReservation(payload: {
    lotteryId: number;
  }): Promise<void> {
    const { lotteryId } = payload;
    const url = this.http.replacedUrl(
      ReservationApiEndpoint.deleteLotteryReservation,
      {
        lotteryId,
      }
    );
    await this.http.delete<void>(url);
  }
}
