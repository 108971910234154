import { UserAdapter } from '@core/adapters';
import { UpdateUserInfoPayload } from '@core/dto';

export class UpdateUserInfoUseCase {
  constructor(private readonly userAdapter: UserAdapter) {
    this.userAdapter = userAdapter;
  }
  async execute(payload: UpdateUserInfoPayload): Promise<any> {
    return await this.userAdapter.updateUserInfo(payload);
  }
}
