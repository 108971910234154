import { ROUTE_NAME } from '@constants';

const auth = {
  path: '/auth',
  redirect: '/auth/signin',
  meta: {
    breadcrumb: false,
    breadcrumbName: '회원서비스',
  },
  children: [
    {
      name: ROUTE_NAME.Signin,
      path: 'signin',
      component: () => import('@views/auth/Signin.vue'),
      meta: {
        breadcrumb: false,
        breadcrumbName: '로그인',
      },
    },
    {
      name: ROUTE_NAME.SigninAdmin,
      path: 'signin-admin',
      component: () => import('@views/auth/SigninAdmin.vue'),
      meta: {
        breadcrumbName: '관리자 로그인',
      },
    },
    {
      name: ROUTE_NAME.SignupAgree,
      path: 'signup-agree',
      component: () => import('@views/auth/SignupAgree.vue'),
      meta: {
        breadcrumbName: '회원가입 동의',
      },
    },
    {
      name: ROUTE_NAME.SignupCertification,
      path: 'signup-certification',
      component: () => import('@views/auth/SignupCertification.vue'),
      meta: {
        breadcrumbName: '회원가입 인증',
      },
    },
    // {
    //   name: ROUTE_NAME.Signup,
    //   path: 'signup',
    //   component: () => import('@views/auth/Signup.vue'),
    //   meta: {
    //     breadcrumb: true,
    //     breadcrumbName: '회원가입',
    //   },
    // },
    {
      name: ROUTE_NAME.SignupComplete,
      path: 'signup-complete',
      component: () => import('@views/auth/SignupComplete.vue'),
      meta: {
        breadcrumbName: '회원가입 완료',
      },
    },
    {
      name: ROUTE_NAME.UserInfoModify,
      path: 'user-info-modify',
      component: () => import('@views/auth/UserInfoModify.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '회원정보 수정',
        requiresAuth: true,
      },
    },
    {
      name: ROUTE_NAME.FindId,
      path: 'find-id',
      component: () => import('@views/auth/FindId.vue'),
      meta: {
        breadcrumb: true,
        breadcrumbName: '아이디 찾기',
      },
    },
    {
      name: ROUTE_NAME.FindPassword,
      path: 'update-password',
      component: () => import('@views/auth/UpdatePassword.vue'),
      meta: {
        breadcrumb: false,
        breadcrumbName: '비밀번호 찾기',
      },
    },
    {
      name: ROUTE_NAME.NewPasswordAccount,
      path: 'new-password-account',
      component: () => import('@views/auth/NewPasswordAccount.vue'),
      meta: {
        breadcrumb: false,
        breadcrumbName: '새 비밀번호 설정',
      },
    },
  ],
};

export default auth;
