<template>
  <div class="terms">
    <h1>개인정보취급방침 & 영상정보처리기기 설치운영</h1>
    <div class="scroll">
      개인정보의 처리를 위탁하는 경우에는 수탁자, 수탁범위, 공유 정보의 범위 등에 관한 사항을 서면, 전자우편, 전화, SMS 또는 홈페이지를 통해 공지합니다.<br><br>

      (주)올림픽컨트리클럽은 (이하 '회사'는) 이용자들의 개인정보보호를 매우 중요시하며, 이용자가 회사의 서비스를 이용함과 동시에 온라인상에서 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다.<br>
      이에 회사는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 정보통신서비스 제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정 및 정부가 제정한 개인정보보호지침을 준수하고 있습니다.<br>
      회사는 개인정보 처리방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.<br>
      회사는 개인정보 처리방침을 홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실 수 있도록 조치하고 있습니다.<br>
      회사의 개인정보 처리방침은 정부의 법률 및 지침 변경이나 회사의 내부방침 변경 등으로 인하여 변경될 수 있습니다. 이용자들께서는 사이트 방문 시 수시로 확인하여 주시기 바랍니다. 회사의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.<br><br>
      1. 개인정보 수집에 대한 동의<br><br>

      2. 개인정보의 수집항목 및 이용목적<br><br>

      3. 개인정보 수집방법<br><br>

      4. 수집하는 개인정보의 보유, 이용기간 및 파기<br><br>

      5. 수집한 개인정보의 제공<br><br>

      6. 이용자 자신의 개인정보 관리(열람,정정,삭제 등)에 관한 사항<br><br>

      7. 쿠키(cookie)의 운영에 관한 사항<br><br>

      8. 비회원 고객의 개인정보 관리<br><br>

      9. 개인정보의 위탁처리<br><br>

      10. 개인정보의 제3자제공(이용자의 별도 요청이 있을 때에 한하여 제공)<br><br>

      11. 개인정보의 보관 안정성 확보<br><br>

      12. 개인정보관련 의견수렴 및 불만처리에 관한 사항<br><br>

      13. 개인정보 보호책임자 지정 - 성명 및 연락처<br><br>

      14. 권익침해 구제방법<br><br>

      15. 공지의 의무<br><br>

      1. 개인정보 수집에 대한 동의<br>
      회사는 이용자들이 인터넷서비스 이용약관 및 개인정보 수집에 대한 동의에 대하여 「동의함」버튼 또는 「동의안함」버튼을 클릭할 수 있는 절차를 마련하여「동의함」버튼을 클릭하면 인터넷 서비스 이용약관 및 개인정보 수집에 대해 동의한 것으로 봅니다. 오프라인의 경우 “개인정보 수집 및 활용 동의서”와 같은 별도의 서식을 통해 개인정보 수집에 대한 동의를 받고 있습니다.<br>
      인터넷 서비스 이용약관 및 개인정보 수집에 대해 동의하지 아니하는 경우 인터넷을 통한 예약서비스 및 회원가입 서비스를 제공 받지 못하게 됩니다.<br><br>

      2. 개인정보의 수집항목 및 이용목적<br>
      "개인정보"라 함은 생존하는 개인에 관한 정보로서 정보에 포함되어 있는 성명 등의 사항에 의하여 개인을 식별할 수 있는 정보(정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. 회사는 이용자들에게 인터넷 예약서비스 및 예약확정 및 취소 결과 SMS 회신 서비스를 비롯한 골프장 예약 및 이용 서비스를 제공하기 위하여 이용자의 개인정보를 수집합니다. 회사는 이용자의 사전 동의 없이는 이용자의 개인정보를 공개하지 않으며, 수집된 정보는 아래와 같이 이용합니다.<br><br>

      첫째, 이용자들이 제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수 있습니다.<br>
      회사는 신규 서비스개발이나 컨텐츠의 확충 시에 기존 이용자들이 회사에 제공한 개인정보를 바탕으로 개발해야 할 서비스의 우선 순위를 보다 더 효율적으로 정하고, 회사는 이용자들이 필요로 할 컨텐츠를 합리적으로 선택하여 제공할 수 있습니다.<br><br>

      둘째, 오프라인상의 개인정보 수집은 별도의 “개인정보수집 및 활용 동의서” 또는 별도의 서식을 통하여 수집항목과 이용목적을 안내합니다.<br><br>

      셋째, 온라인상으로 수집하는 개인정보 항목과 이용목적은 다음과 같습니다.<br><br>
      <table class="terms-table">
        <colgroup>
          <col width="20%" />
          <col width="40%" span="2" />
        </colgroup>
        <tbody>
          <tr>
            <th>구분</th>
            <th>수집하는<span>개인정보 항목</span></th>
            <th>이용목적</th>
          </tr>
          <tr>
            <td rowspan="2">필수항목</td>
            <td>성명, 아이디, 비밀번호, 비밀번호 확인,<br>성별, 생년월일, 휴대폰 번호</td>
            <td>이용자 식별, 예약정보 전송, 공지사항 전달</td>
          </tr>
          <tr>
            <td>IP Address, 로그인 일자</td>
            <td>이용여부 확인, 부정이용 방지</td>
          </tr>
          <tr>
            <td rowspan="2">선택항목</td>
            <td>주소, E-mail</td>
            <td>공지사항 전달, DM발송</td>
          </tr>
          <tr>
            <td>직장명, 직위, 차량번호, 핸디캡</td>
            <td>고객맞춤 서비스 제공</td>
          </tr>
        </tbody>
      </table><br>
      3. 개인정보 수집방법<br>

      회사는 이용자들이 회원서비스를 이용하기 위해 회원으로 가입하실 때 서비스 제공을 위한 필수적인 정보들을 온라인과 오프라인상에서 입력 받고 있습니다.<br>
      개인정보 수집 방법 : 홈페이지, 모바일 앱, 예약신청 전화, 예약신청 팩스, 내장객 방명록, 각 이벤트에 특성화 된 서식을 통하여 수집하고 있으나 이용자의 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향, 범죄기록, 건강상태, 성생활 등)는 수집하지 않으며 부득이하게 수집해야 할 경우 이용자의 사전동의를 반드시 구할 것입니다. 그리고, 입력하신 정보를 이용자들에게 사전에 밝힌 목적 이외에 다른 목적으로는 사용하지 않으며 외부로 유출하지 않습니다.<br><br>

      4. 수집하는 개인정보의 보유, 이용기간 및 파기<br>
      이용자가 인터넷 회원으로서 회사에 제공하는 서비스를 이용하는 동안 회사는 이용자들의 개인정보를 계속적으로 보유하며 예약서비스 제공을 위해 이용합니다. 다만, 아래의 "6. 이용자 자신의 개인정보관리(열람,정정,삭제 등)에 관한 사항" 에서 설명한 절차와 방법에 따라 회원 본인이 직접 삭제하거나 수정한 정보, 회원탈퇴를 요청한 경우에는 재생할 수 없는 방법에 의하여 디스크에서 완전히 삭제하며 추후 열람이나 이용이 불가능한 상태로 처리됩니다.<br>
      이용자의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 지체 없이 파기합니다. 다만, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br>
      - 계약 또는 청약철회에 관한 기록 : 5년<br>
      - 대금결제 및 재화의 공급에 관한 기록 : 5년<br>
      - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
      회사는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.<br>
      종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br>
      - 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br><br>

      5. 수집한 개인정보의 제공<br>
      회사는 이용자들의 개인정보를 "2. 개인정보의 수집항목 및 이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br>

      - 이용자들이 사전에 공개에 동의한 경우<br>
      - 서비스 제공에 따른 요금정산을 위하여 필요한 경우<br>
      - 자사 서비스를 이용하여 타인에게 정신적, 물질적 피해를 줌으로써 그에 대한 법적인 조치를 취하기 위하여 개인정보를 공개해야 한다고 판단되는 충분한 근거가 있는 경우<br>
      - 기타 법에 의해 요구된다고 선의로 판단되는 경우 (ex. 관련법에 의거 적법한 절차에 의한 정부/수사기관의 요청이 있는 경우 등)<br>
      - 통계작성, 학술연구나 시장조사를 위하여 특정개인을 식별할 수 없는 형태로 연구단체 등에 제공하는 경우<br>
      - 이용자의 서비스 이용에 따른 불만사항 및 분쟁의 처리를 위하여 분쟁처리 전문업체에 해당사항의 처리를 위하여 반드시 필요한 최소한의 개인정보를 제공하는 경우<br><br>

      6. 이용자 자신의 개인정보 관리(열람,정정,삭제 등)에 관한 사항<br>
      이용자가 원하실 경우 본인의 정보에 대해서는 언제라도 개인정보를 열람하실 수 있으며 보관된 정보를 수정하실 수 있습니다. "이용자의 개인정보 수정"은 회원정보변경 메뉴에서 "회원탈퇴"는 홈페이지 로그인(Login) 후「회원탈퇴」를 선택하여 이용하실 수 있습니다. 홈페이지를 통한 개인정보의 열람이나 정정에 불편이 있는 경우에는 전화를 통해서도 열람이나 정정을 요청하실 수 있습니다. 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br><br>

      개인정보 열람청구 접수,처리부서<br>
      부서명 : 관리팀<br>
      담당자 : 김수진 차장<br>
      연락처 : 전화 031-962-0101, 이메일 kimsj@olympicsports.co.kr / Fax 031-962-2746<br><br>

      7. 쿠키(cookie)의 운영에 관한 사항<br>
      쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 데이터 꾸러미로 이용자 컴퓨터의 하드디스크에 저장됩니다. 쿠키는 실행이 불가능한 작은 데이터로 PC의 다른 부분에는 전혀 영양을 주지 않습니다.<br><br>

      1) 쿠키의 사용 목적<br>
      이용자의 접속빈도나 방문시간 등을 분석, 이용자의 취향과 관심분야 파악 및 자취추적을 통한 타겟 마케팅 및 개인 맞춤서비스 제공의 척도로 활용합니다.<br>
      ※ 쿠키 설정 거부 방법<br>
      - 예: 쿠키 설정을 거부하는 방법으로는 사용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br>
      -설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 탭에서 개인정보보호 수준을 설정하시면 됩니다.(단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 제공에 어려움이 있을 수 있습니다.)<br><br>

      8. 비회원 고객의 개인정보관리<br>
      회사는 비회원 고객의 경우 전화, 후론트 방명록 및 각 이벤트에 특화된 별도 서식을 통하여 개인정보를 수집합니다. 비회원 고객의 개인정보는 제4항에 준하여 관리 및 파기 됩니다.<br><br>

      9. 개인정보의 위탁처리<br>
      회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고 있으며 관계법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br><br>
      <table class="terms-table">
        <colgroup>
          <col width="33.3%" span="3" />
        </colgroup>
        <tbody>
          <tr>
            <th>수탁업체</th>
            <th>수탁범위</th>
            <th>공유정보</th>
          </tr>
          <tr>
            <td>비즈뿌리오</td>
            <td>SMS 전송, 문자수신거부,<br>카카오톡 알림톡 발송</td>
            <td>성명, 휴대폰 번호</td>
          </tr>
          <tr>
            <td>스마트스코어</td>
            <td>디지털 스코어카드 작성</td>
            <td>성명, 티오프 시간</td>
          </tr>
          <tr>
            <td>SBS골프 / 카카오골프 /<br> X골프 / 스마트스코어</td>
            <td>골프예약</td>
            <td>성명, 티오프 시간</td>
          </tr>
        </tbody>
      </table><br>

      10. 개인정보의 제3자제공(이용자의 별도 요청이 있을 때에 한하여 제공)<br>
      회사는 이용자에게 편리하고 혜택이 있는 다양한 서비스를 제공하기 위하여 타 업체와 제휴합니다. 이용자가 제휴업체 이용을 희망하는 경우에만 제공되며 별도의 “이용신청서” 또는 이메일을 통하여 이용자의 정보제공 동의를 구한 후 개인정보를 제공합니다. 제공되는 개인정보의 항목은 회원가입 시 (주)올림픽컨트리클럽에 제공한 개인정보 중 서비스 이용에 반드시 필요한 최소한의 정보만을 제공합니다.<br>
      - 제공대상 : 업무제휴골프장<br>
      - 제공 개인정보 항목 : 성명, 휴대폰 번호<br>
      - 정보 이용목적 : 시설이용 예약 및 요금할인<br>
      - 정보 보유 및 이용기간 : 수집목적 달성 즉시 파기<br><br>

      11. 개인정보의 보관 안전성 확보<br>
      회사는 수집된 개인정보의 안전성 확보를 위하여 다음의 물리적, 기술적, 관리적 조치를 취하고 있습니다.<br>
      - 물리적 조치<br>
      개인정보가 포함된 서류와 전산매체는 잠금장치가 있는 안전한 장소에 보관하고 있으며 데이터베이스시스템의 안전성 확보를 위하여 잠금장치가 설치된 전산실을 운영하고 출입을 통제하고 있습니다.<br>
      - 기술적 조치<br>
      회사는 해킹 및 악성코드 등의 감염으로 인한 정보 유출을 방지하기 위하여 보안프로그램을 설치 운영하고 있으며 허가되지 않은 자의 시스템 접근 차단을 위하여 방화벽 및 침입차단 시스템을 운영하고 있습니다.<br>
      - 관리적 조치<br>
      회사는 개인정보보호 관리계획을 수립하여 이를 시행하고 있으며 관리계획에 따라 전산처리 시스템 점검 및 개인정보보호 관련 자체감사를 실시하고 있습니다.<br>
      - 개인정보의 암호화<br>
      회사는 수집한 개인정보 중 관계법령에 따라 암호화 하여야 하는 정보에 대해서는 암호화를 통하여 안전하게 보관합니다.<br><br>

      12. 개인정보관련 의견수렴 및 불만처리에 관한 사항<br>
      회사는 개인정보보호와 관련하여 이용자 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위한 절차와 방법을 마련하고 있습니다.<br>
      이용자들은 하단에 명시한 "13. 개인정보 보호책임자 지정 항을 참고하여 전화나 이메일을 통하여 불만사항을 신고할 수 있고, 회사는 이용자들의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다.<br><br>

      13. 개인정보 보호책임자 지정 - 성명 및 연락처<br>
      이용자 개인아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자 자신에게 있습니다. 회사는 이용자의 비밀번호를 일방향 암호화하여 보관하며 이용자의 비밀번호에 대해 어떠한 방법으로도 질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지 않도록 각별히 주의하시기 바랍니다. 특히, 공공장소의 온라인상에서 접속해 있을 경우에는 더욱 유의하셔야 합니다. 회사는 이용자가 유용한 서비스를 안전하게 이용할 수 있도록 최선을 다하고 있습니다.회사는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보보호책임자와 개인정보보호담당자를 지정하고 있고 연락처는 아래와 같습니다.<br><br>
      <table class="terms-table">
        <colgroup>
          <col width="20%" span="3" />
          <col width="25%" />
          <col width="*" />
        </colgroup>
        <tbody>
          <tr>
            <th>구분</th>
            <th>성명</th>
            <th>소속/직책</th>
            <th>이메일</th>
            <th>연락처</th>
          </tr>
          <tr>
            <td>개인정보보호 책임자</td>
            <td>김수진</td>
            <td>관리팀 / 차장</td>
            <td>kimsj<span>@olympicsports.co.kr</span></td>
            <td>031-962-0101</td>
          </tr>
          <tr>
            <td>개인정보보호 담당자</td>
            <td>김수진</td>
            <td>관리팀 / 차장</td>
            <td>kimsj<span>@olympicsports.co.kr</span></td>
            <td>031-962-0101</td>
          </tr>
        </tbody>
      </table><br>

      14. 권익침해 구제방법<br>
      정보주체는 아래의 국가기관에게 개인정보침해에 대한 피해구제 및상담을 문의하실 수 있습니다.(아래의 기관은 (주)올림픽컨트리클럽 (이하 '회사'는)와는 별개의 기관으로 (주)올림픽컨트리클럽 (이하 '회사'는)의 자체적인 개인정보 불만처리,피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하신 경우 이용하시기 바랍니다.<br>
      - 개인정보침해신고센터 : http://privacy.kisa.or.kr/ 국번없이 118<br>
      - 개인정보분쟁조정위원회 : https://www.kopico.go.kr / 02-2100-2499<br>
      - 대검찰청 사이버수사과 : http://www.spo.go.kr / 국번없이 1301<br>
      - 경찰청 사이버안전국 : http://cyberbureau.police.go.kr / 국번없이 182<br><br>

      15. 공지의 의무<br>
      현 개인정보처리방침의 내용은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 수 있습니다. 변동이 있을 시에는 시행하기 최소10일전에 홈페이지의 “공지사항”을 통해 변경이유 및 내용 등을 공지할 것입니다.<br>
      ο 본 방침은 : 2011 년 11 월 30 일 부터 시행됩니다.<br>
      ο 본 방침은 : 2020 년 01 월 01 일 부터 시행됩니다.<br><br>

      <span class="terms-title">[영상정보처리기기 설치 운영]</span><br>
      회사는 아래와 같이 영상정보처리기기를 설치 운영하고 있습니다<br><br>

      1) 영상정보처리기기 설치근거, 목적<br>
      - 회사의 시설안전관리 및 화재방지<br>
      - 분실물방지 및 고객 편의<br>
      - 차량도난및 파손방지<br><br>

      2) 설치 대수, 설치위치, 촬영범위<br>
      - 클럽하우스 내,외부 주차장 등 12대 설치<br>
      - 촬영범위 : 주요시설물 및 기기가 설치된 지역 전반을 촬영<br><br>

      3) 영상정보처리기기에 대한 관리 책임자 및 접근 권한자<br>
      - 관리 책임자 : 시설팀 김호범<br><br>

      4) 영상정보 촬영시간, 보관기간, 보관장소, 처리방법<br>
      - 촬영시간 : 24시간 촬영<br>
      - 보관기간 : 촬영 시부터 30일<br>
      - 보관장소 및 처리방법 : 시설팀 시스템에 보관,처리<br><br>

      5) 영상정보 확인 방법<br>
      - 관리 책임자에 요구<br><br>

      6) 정보주체의 영상정보 열람 등 요구에 대한 조치<br>
      - 개인영상정보 열람, 존재 확인 청구서로 신청하여야하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명 ,신체, 재산 이익을 위해 필요한 경우에 한해 열람 허용합니다.<br><br>

      7) 영상정보 보호를 위한 기술적, 관리적, 물리적 조치<br>
      - 내부관리계획 수립, 접근통제 및 접근 권한 제한, 영상정보의 안전한 저장, 전송기술 적용, 처리기록 보관 및 위·변조 방지 조치, 보관시설 마련 및 잠금 장치 설치 등
    </div>
  </div>
</template>
<script setup lang="ts">
</script>

<style scoped lang="scss">
/*팝업*/
.terms {
  width: 1104px;
  height: 580px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #ccc;
  padding: 56px 62px;
}
.terms h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 40px;
}
.terms .scroll {
  overflow-y: auto;
  height: 445px;
  font-size: 1rem;
  font-weight: 300;
  padding-right: 5px;
  line-height: 1.13rem;
  padding-bottom: 56px;
}

/*팝업 테이블*/
.terms-table {
  width: 100%;
  border-top: 2px solid #222;
  border-bottom: 1px double #222;
  text-align: center;
  font-size: 1.13rem;
  margin-bottom: 1rem;
}
.terms-table tbody th {
  font-size: 1rem;
  font-weight: 700;
  color: #666;
  background: #f9f9f9;
  border-right: 1px solid #ccc;
  padding: 18px 0;
}
.terms-table tbody th:last-child {
  border-right: 0;
}
.terms-table tbody td {
  padding: 21px 0;
  font-weight: 300;
  border: 1px solid #ccc;
  line-height: 18px;
}
.terms-table tbody td:first-child {
  border-left: 0;
}
.terms-table tbody td:last-child {
  border-right: 0;
}

/*팝업 스크롤바*/
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #ccc;
}
::-webkit-scrollbar-thumb {
  background-color: #eb7617;
}

/*팝업 닫기 버튼*/
.modal-footer button {
  background: rgba(255, 255, 255, 0.11);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
.modal-footer button img {
  width: 24px;
  height: 24px;
}

.terms-title{
  font-weight: 700;
  font-size: 1.5rem;
  margin: 20px 0;
  display: block;
}
</style>
