<template>
  <div class="checkbox-container">
    <input
      type="checkbox"
      :id="id"
      :value="modelValue"
      :checked="modelValue"
      @change="handleChange"
    />
    <label :for="id"><slot>Default Label</slot></label>
  </div>
</template>

<script setup lang="ts">
interface CheckboxProps {
  id: string;
  modelValue: boolean;
}

defineProps<CheckboxProps>();
const emits = defineEmits(['update:modelValue']);

const handleChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emits('update:modelValue', target.checked);
};
</script>
<style scoped lang="scss">
input[type=checkbox] {
  display: none;
}

input[type=checkbox]+label {
  background-repeat: no-repeat;
  background-image: url('@assets/img/chk.svg');
  background-size: 32px 32px;
  padding-left: 35px;
  padding-right: 20px;
  cursor: pointer;
  display: flex;
  height: 32px;
  align-items: center;
}

input[type=checkbox]+label:last-child {
  padding-right: 0;
}

input[type=checkbox]:checked+label {
  background-image: url('@assets/img/chk_on.svg');
  background-size: 32px 32px;
}

/*회원가입 - 체크박스 커스텀*/
.agree-area input[type=checkbox]+label {
  padding-left: 65px;
  background-position: left -2px center;
}

/*모바일*/
/*회원가입 - 체크박스 커스텀*/
@media (min-width:320px) and (max-width: 1279px){
  .agree-area input[type=checkbox]+label {
    background-size: 24px 24px;
    background-position: left 7px center;
  }
  .agree-area input[type=checkbox]:checked+label{
    background-size: 24px 24px;
  }
  /*전체동의 체크박스 위치값*/
  .signup-check input[type=checkbox]+label {
    background-size: 24px 24px;
    background-position: bottom 20px center;
    display: flex;
    height: 80px;
    align-items: baseline;
    padding-left: 0;
    justify-content: center;
  }
}
</style>
