<template>
  <input
    :id="props.id"
    :type="props.type"
    v-model="inputValue"
    :placeholder="placeholder"
    :disabled="props.disabled"
    :class="classes"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
interface IProps {
  id: string;
  type?: string;
  modelValue: string;
  placeholder?: string;
  max?: string;
  onlyNumber?: boolean;
  disabled?: boolean;
  phone?: boolean;
  birthday?: boolean;
}
import { ref, watch } from 'vue';
import { useAlertStore } from '@stores';
import { ERROR_MESSAGES } from '@constants';
import { formatBirthday, phoneNumberFormatter } from '@core/utils';

const props = withDefaults(defineProps<IProps>(), {
  id: '',
  type: 'text',
  modelValue: '',
  placeholder: '입력해주세요',
  max: '',
  onlyNumber: false,
  disabled: false,
});

const emits = defineEmits(['update:modelValue']);
const inputValue = ref<string>(props.modelValue);
const alertStore = useAlertStore();

const classes = computed(() => {
  const disabled = props.disabled ? 'disabled' : '';
  return `${disabled}`;
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (props.phone || props.birthday) {
      const removeHyphenStr = newValue.replace(/[^0-9]/g, '');
      if (props.phone) {
        inputValue.value = phoneNumberFormatter(removeHyphenStr);
      } else if (props.birthday) {
        inputValue.value = formatBirthday(removeHyphenStr);
      }
    } else {
      inputValue.value = newValue;
    }
  }
);

watch(
  () => inputValue.value,
  (newValue, oldValue) => {
    if (props.max && newValue.length > Number(props.max)) {
      alertStore.showAlertDialog(ERROR_MESSAGES['EXCEED_MAX_VALUE']);
      inputValue.value = oldValue;
      return;
    }
    if (props.onlyNumber) {
      const regExp = /^[0-9]*$/;
      if (!regExp.test(newValue)) {
        inputValue.value = oldValue;
        return;
      }
    }
    if (props.phone || props.birthday) {
      const removeHyphenString = newValue.replace(/-/g, '');
      emits('update:modelValue', removeHyphenString);
      return;
    }
    emits('update:modelValue', newValue);
  }
);
</script>

<style scoped lang="scss">
input {
  outline: 1px solid #ccc;
  border: 2px solid transparent;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  padding: 16px;
  font-weight: 400;
  &.disabled {
    @apply text-gray-400;
  }
}
input:focus {
  border-radius: 4px;
  border: 2px solid #eb7617;
  outline: none;
}
</style>
