import { UserAdapter } from '@core/adapters';
import { FetchUserInfoResponse } from '@core/dto';
export class FetchUserInfoUseCase {
  constructor(private readonly userAdapter: UserAdapter) {
    this.userAdapter = userAdapter;
  }
  async execute(): Promise<FetchUserInfoResponse> {
    return await this.userAdapter.fetchUserInfo();
  }
}
