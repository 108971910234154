import { ref, watch } from 'vue';
import { useRouter, RouteLocationNormalized } from 'vue-router';
import { ROUTE_NAME } from '@constants';

export const useIsMain = () => {
  const { currentRoute } = useRouter();
  const isMain = ref<boolean>(false);

  watch(currentRoute, (value: RouteLocationNormalized) => {
    // isMain.value = false;

    value.name === ROUTE_NAME.Main
      ? (isMain.value = true)
      : (isMain.value = false);
  });

  return {
    isMain,
  };
};
