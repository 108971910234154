<template>
  <ABaseModal ref="modal">
    <div class="modal">
      <div class="modal-header">
        <slot name="modal-header" />
      </div>
      <div class="modal-body">
        <slot name="modal-body" />
      </div>
      <div class="modal-captcha">
        <slot name="modal-captcha" />
      </div>
      <div class="modal-sub-footer">
        <slot name="modal-sub-footer" />
      </div>
      <div class="modal-footer">
        <template v-if="$slots['modal-footer']">
          <slot name="modal-footer" />
        </template>
        <template v-else>
          <button @click="close" class="modal-footer-button close">
            <img src="@assets/img/pop-close.svg" alt="닫기버튼" />
          </button>
        </template>
      </div>
    </div>
  </ABaseModal>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { ABaseModal } from '@atoms';
import { useScrollLock } from '@composables';
import { useRouter } from 'vue-router';

const { lockScroll, unLockScroll } = useScrollLock();
defineProps({
  title: {
    type: String,
    default: 'Modal Title',
  },
  subTitle: {
    type: String,
    default: 'Modal Subtitle',
  },
});

const modal = ref();
const message = ref('');
const { currentRoute } = useRouter();
const emits = defineEmits(['close']);

const open = (msg: string) => {
  lockScroll();
  modal.value.open();
  message.value = msg;
};

const close = () => {
  unLockScroll();
  modal.value.close();
  emits('close', true);
};

watch(currentRoute, () => {
  close();
});

defineExpose({
  open,
  close,
});
</script>

<style lang="scss" scoped>
.modal {
  @apply mobile:wi-[320];
  &-header {
    @apply flex flex-col gap-2 pb-20;
  }
  &-title {
    @apply text-lg font-bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.5px;
  }
  &-subtitle {
    @apply text-base;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: left;
  }
  &-body {
    @apply py-6;
  }
  &-footer {
    @apply flex justify-between gap-10;
    &-button {
      @apply w-full text-base;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: -0.8px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.close {
        @apply text-white rounded-full wi-[48] he-[48] mx-auto mt-10;
        background: rgba(255, 255, 255, 0.11);
        width: 56px;
        height: 56px;
      }
      &.complete {
        @apply text-white;
        background: #005aa9;
        padding: 7px 0 9px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1279px) {
  .modal {
    width: 92%;
  }
  .modal-footer-button.close {
    width: 48px;
    height: 48px;
  }
  .modal-footer-button.close img {
    width: 16px;
    height: 16px;
  }
}
</style>
