<template>
  <!--스크롤시 헤더 활성화 클래스명 active-->
  <div>
    <div>
      <!--        :class="isMenuClicked ? 'active' : ''"-->
      <header :class="{ active: isScroll || !isMain || isMenuClicked }">
        <!--헤더컨텐츠-->
        <div class="header-box">
          <!--날씨-->
          <MWeatherInfo />

          <!--로고-->
          <div class="logo">
            <router-link to="/">
              <img
                v-if="isScroll || !isMain || isMenuClicked"
                src="@assets/img/logo-color.svg"
                alt="헤더로고_컬러"
              />
              <img v-else src="@assets/img/logo-w.svg" alt="헤더로고" />
            </router-link>
          </div>

          <!--버튼-->
          <div class="quick">
            <ul class="q-btn">
              <li v-if="!commonStore.isSignIn" @click="handleSignin">로그인</li>
              <li v-if="commonStore.isSignIn" @click="handleSignOut">
                로그아웃
              </li>
              <li v-if="commonStore.isSignIn">
                <router-link :to="{ name: ROUTE_NAME.Reservation }"
                  >예약하기</router-link
                >
              </li>
              <li @click="handleToggleMenu(!isMenuClicked)">
                <img
                  v-if="isMain && !isScroll && !isMenuClicked"
                  src="/src/infrastructure/assets/img/toggleMenu_icon.svg"
                  alt="메뉴_화이트"
                />
                <img
                  v-if="
                    (isMain && isScroll && !isMenuClicked) ||
                    (!isMain && !isMenuClicked)
                  "
                  src="@assets/img/menu_icon_black.svg"
                  alt="메뉴_블랙"
                />
                <span v-if="isMenuClicked"
                  ><img src="@assets/img/web-hamburger.svg" alt="닫기" />
                </span>
              </li>
            </ul>
          </div>
        </div>

        <!--메뉴 닫길때 클래스명 close-lab-bar 입니다!
    lnb css 디폴트값 display:none-->
        <ul :class="isMenuClicked ? 'lnb active-lab-bar' : 'lnb close-lab-bar'">
          <li>
            <ul class="menu">
              <li class="tit">예약서비스</li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.Reservation }" class=""
                  >예약하기</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: ROUTE_NAME.ReservationInformation }"
                  class=""
                  >예약안내</router-link
                >
              </li>
              <li>
                <router-link
                  :to="{ name: ROUTE_NAME.ReservationStatus }"
                  class=""
                  >예약확인</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <ul class="menu">
              <li class="tit">클럽소개</li>
              <li>
                <router-link
                  :to="{ name: ROUTE_NAME.ClubIntroduction }"
                  class=""
                  >클럽소개</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.Location }" class=""
                  >오시는 길</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.ClubCi }" class=""
                  >CI소개</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <ul class="menu">
              <li class="tit">이용안내</li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.Guide }" class=""
                  >이용방법</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.FeeInformation }" class=""
                  >요금안내</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.RuleInformation }" class=""
                  >로컬룰</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <ul class="menu">
              <li class="tit">코스안내</li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.CourseStrategy }" class=""
                  >코스공략</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <ul class="menu">
              <li class="tit">시설안내</li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.ClubHouse }" class=""
                  >클럽하우스</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.StartHouse }" class=""
                  >스타트하우스</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.OndolRoom }" class=""
                  >토방</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.Light }" class=""
                  >라이트시설</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <ul class="menu">
              <li class="tit">고객지원</li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.Notice }" class=""
                  >공지사항</router-link
                >
              </li>
              <li v-if="isAdmin">
                <router-link :to="{ name: ROUTE_NAME.Banner }" class=""
                  >배너관리</router-link
                >
              </li>
              <li v-if="commonStore.isSignIn">
                <router-link :to="{ name: ROUTE_NAME.UserInfoModify }" class=""
                  >회원정보수정</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: ROUTE_NAME.HoleInOne }" class=""
                  >홀인원</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </header>

      <!--사이드 퀵메뉴-->
      <div class="side-btn">
        <a href="https://thecommunitya.co.kr/index.php" target="_blank"
          ><img src="@assets/img/side04.jpg" alt="커뮤니티A홈"
        /></a>
        <a href="https://www.citycollege.ac/about-4" target="_blank"
          ><img src="@assets/img/side01.jpg" alt="씨티평생교육원"
        /></a>
        <a href="https://www.goyang-plantation.com/" target="_blank"
          ><img src="@assets/img/side02.jpg" alt="PLANTATION"
        /></a>
        <a href="https://oasismedihome.kr/oasismed/?mode=main" target="_blank"
          ><img src="@assets/img/side03.jpg" alt="오아시스메디홈"
        /></a>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { MWeatherInfo } from '@molecules';
import { useCommonStore, useUserStore } from '@stores';
import { useSignOut, useScroll, useIsMain } from '@composables';
import { useRouter } from 'vue-router';
import { ROUTE_NAME } from '@constants';

const commonStore = useCommonStore();
const userStore = useUserStore();
const isAdmin = computed(() => userStore.grant === 'WEB_ADMIN');
const { handleSignOut: signOut } = useSignOut();
const { isScroll } = useScroll();
const { isMain } = useIsMain();
const { currentRoute, push } = useRouter();
const isMenuClicked = ref<boolean>(false);

const handleToggleMenu = (value: boolean) => {
  isMenuClicked.value = value;
};
const handleSignin = () => {
  commonStore.setSigninOpen(true);
};

const handleSignOut = async () => {
  await signOut();
  await push({ name: ROUTE_NAME.Main });
};

watch(currentRoute, () => {
  handleToggleMenu(false);
});
</script>

<style scoped lang="scss">
header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6)
  );
  height: 90px;
}
.header-box {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 16px 0;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  height: 90px;
}
.header-box .weather {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #f3f3f3;
  font-size: 15px;
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
}
.header-box .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.q-btn {
  position: absolute;
  right: 72px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 33px;
  z-index: 1;
  align-items: center;
}
.q-btn li {
  color: #fff;
  position: relative;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.q-btn li:first-child {
  text-decoration: underline;
}
.q-btn li::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 135%;
  width: 1px;
  height: 12px;
  background: #ccc;
}
.q-btn li:last-child::before {
  display: none;
}

/*사이드*/
.side-btn {
  position: fixed;
  right: 0;
  top: 50%;
  width: 60px;
  height: 180px;
  z-index: 10;
  transform: translateY(-50%);
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
  background: transparent;
}

/*헤더 색상변경(화이트)*/
header.active .header-box {
  background: #fff;
}
//header.active::after{
//  background: none;
//}
header.active .header-box .weather {
  color: #666;
}
header.active .header-box .q-btn li {
  color: #666;
}

/*메뉴 활성화*/
header .lnb {
  background: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  position: relative;
  padding: 30px 0 50px;
  display: none;
}
.lnb::after {
  width: 100%;
  height: 100%;
  content: '';
  background: #fff url('@assets/img/menu-bg.jpg') center top;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.02;
  z-index: 0;
}
header .lnb > li {
  position: relative;
  width: 10%;
  z-index: 1;
}
header .lnb > li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 250px;
  background: #eee;
}
header .lnb > li:last-child::after {
  display: none;
}
header .lnb .menu li {
  font-size: 20px;
  margin-bottom: 24px;
  text-align: center;
  height: 100%;
}
header .lnb .menu li:first-child {
  margin-bottom: 40px;
}
header .lnb li:last-child {
  margin-bottom: 0;
}
header .lnb .menu li:hover {
  text-decoration: underline;
  font-weight: 600;
}
header .lnb .menu li:first-child:hover {
  text-decoration: none;
}
header .lnb li .tit {
  color: #eb7617;
  font-weight: 700;
  font-size: 16px;
}

/*메뉴열기 모션*/
header .active-lab-bar {
  animation: showLnb 0.3s ease;
  display: flex;
}
header .close-lab-bar {
  animation: showLnb 0.3s ease;
  display: none;
}
@keyframes showLnb {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*메뉴닫기 모션*/
header .close-lab-bar {
  animation: hideLnb 0.3s ease;
}
@keyframes hideLnb {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-150px);
  }
}

/*팝업닫기*/
.pop-close {
  background: rgba(255, 255, 255, 0.11);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 16px;
  margin: 16px auto 0;
}
</style>
