import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import {
  // useMetaStore,
  useCommonStore,
  useAlertStore,
  // useUserStore,
} from '@stores';
// import { ROUTE_NAME } from '@constants';

const Router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  linkExactActiveClass: 'route-active',
});

Router.beforeEach(async (to, _, next) => {
  const commonStore = useCommonStore();
  const alertStore = useAlertStore();
  if (to.meta.requiresAuth && !commonStore.isSignIn) {
    await alertStore.showAlertDialog('로그인이 필요합니다');
    commonStore.setSigninOpen(true);
    next(false);
    // next('/auth/signin');
  } else if (to.meta.isReady) {
    await alertStore.showAlertDialog('준비중 입니다.');
    next(false);
  } else {
    next();
  }
});

export default Router;
