import { UserAdapter } from '@core/adapters';
import { SignUpPayload } from '@core/dto';
import { UserModel } from '@core/models';
export class SignUpUsecase {
  constructor(private readonly UserAdapter: UserAdapter) {
    this.UserAdapter = UserAdapter;
  }

  async execute(payload: SignUpPayload): Promise<UserModel> {
    return await this.UserAdapter.signUp(payload);
  }
}
