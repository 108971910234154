import { AxiosAdapter, ReservationAdapter } from '@adapters';
import {
  FetchReservationListUseCase,
  FetchReservationDetailUseCase,
} from '@core/useCases';
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
import {
  FetchReservationDetailPayload,
  FetchReservationListResponse,
  TgResveConfirm,
} from '@dto';
import { orderBy } from 'lodash-es';

export const useReservationList = () => {
  const axiosAdapter = new AxiosAdapter();
  const reservationAdapter = new ReservationAdapter(axiosAdapter);
  const fetchReservationListUseCase = new FetchReservationListUseCase(
    reservationAdapter
  );
  const totalItems = computed(() => {
    // return reservationStatus.value?.resveConfirms.length || 0;
    return reservationStatus.value?.total || 0;
  });
  const totalCancelItems = computed(() => {
    // return reservationCancelStatus.value?.resveConfirms.length || 0;
    return reservationCancelStatus.value?.total || 0;
  });
  const totalItemsPerPage = 10;
  const currentPage = ref<number>(1);
  const currentPageCancel = ref<number>(1);
  const partDivision = ref<number>(0);
  const partDivisionCancel = ref<number>(0);
  const searchReservationStatus = ref<string | null>('RESVE');
  const reservationStatus = ref<FetchReservationListResponse>();
  const reservationCancelStatus = ref<FetchReservationListResponse>();
  const offsetReserve = computed(
    () => (currentPage.value - 1) * totalItemsPerPage
  );
  const offsetCancel = computed(
    () => (currentPageCancel.value - 1) * totalItemsPerPage
  );
  const partDivReserve = computed(() =>
    partDivision.value === 0 ? null : partDivision.value
  );
  const partDivCancel = computed(() =>
    partDivisionCancel.value === 0 ? null : partDivisionCancel.value
  );
  const searchParams = computed(() => {
    const fromDate = dayjs().format('YYYY-MM-DD'); // 현재 날짜
    const toDate = dayjs().add(3, 'month').format('YYYY-MM-DD'); // 3개월 후 날짜
    const limit = totalItemsPerPage; // 페이지 당 항목 수
    const offset =
      searchReservationStatus.value === 'RESVE'
        ? offsetReserve.value
        : offsetCancel.value;
    const partDiv =
      searchReservationStatus.value === 'RESVE'
        ? partDivReserve.value
        : partDivCancel.value;
    const resveStatus = searchReservationStatus.value;

    return {
      fromDate,
      toDate,
      limit,
      offset,
      partDiv,
      resveStatus,
    };
  });

  const _orderedList = (list: TgResveConfirm[]) =>
    orderBy(list, [(item) => (item.resveStatus === 'RESVE' ? 0 : 1)], ['asc']);

  const fetchReservationList = async () => {
    searchReservationStatus.value = 'RESVE';
    reservationStatus.value = await fetchReservationListUseCase.execute(
      searchParams.value
    );

    reservationStatus.value.resveConfirms = _orderedList(
      reservationStatus.value.resveConfirms
    );
  };

  const fetchReservationCancelList = async () => {
    searchReservationStatus.value = 'CANCEL';
    reservationCancelStatus.value = await fetchReservationListUseCase.execute(
      searchParams.value
    );

    reservationCancelStatus.value.resveConfirms = _orderedList(
      reservationCancelStatus.value.resveConfirms
    );
  };

  const fetchReservationDetail = async (
    payload: FetchReservationDetailPayload
  ) => {
    const fetchReservationDetailUseCase = new FetchReservationDetailUseCase(
      reservationAdapter
    );
    return await fetchReservationDetailUseCase.execute(payload);
  };

  // watch(
  //   () => searchParams.value,
  //   async () => {
  //     await fetchReservationList();
  //   }
  // );

  return {
    partDivision,
    partDivisionCancel,
    totalItems,
    totalCancelItems,
    totalItemsPerPage,
    currentPage,
    currentPageCancel,
    searchReservationStatus,
    reservationStatus,
    reservationCancelStatus,
    fetchReservationList,
    fetchReservationCancelList,
    fetchReservationDetail,
  };
};
