<template>
  <footer>
    <div class="footer-box">
      <div class="f-text">
        <ul class="terms-link">
          <li
            @click="handleTerms('privacyPolicy')"
            class="fw700 cursor-pointer"
          >
            개인정보취급방침
          </li>
          <li @click="handleTerms('serviceUsageAgree')" class="cursor-pointer">
            골프장 이용약관
          </li>
        </ul>
        <ul class="f-info">
          <li>
            <span>주소</span>경기도 고양시 덕양구 벽제동 465 (주)올림픽
            컨트리클럽
          </li>
          <li><span>대표</span>이관식</li>
          <li><span>사업자등록번호</span>128-81-06238</li>
          <li><span>전화번호</span>031-962-0101</li>
          <li><span>통신판매업신고</span>2011-경기고양-1346</li>
        </ul>
        <p class="copy">Copyright Olympic Country Club ALL RIGHT RESERVED.</p>
      </div>
    </div>
    <MModal ref="modal">
      <template #modal-body>
        <PrivacyPolicy v-if="temrsType === 'privacyPolicy'" />
        <ServiceUsageAgree v-if="temrsType === 'serviceUsageAgree'" />
        <MarketingAgree v-if="temrsType === 'marketingAgree'" />
      </template>
    </MModal>
  </footer>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { MModal } from '@molecules';
import PrivacyPolicy from '@components/common/PrivacyPolicy.vue';
import ServiceUsageAgree from '@components/common/ServiceUsageAgree.vue';
import MarketingAgree from '@components/common/MarketingAgree.vue';

const modal = ref();
const temrsType = ref('');
const handleTerms = (type: string) => {
  temrsType.value = type;
  modal.value.open();
};
</script>

<style scoped lang="scss">
footer {
  background: #222;
  color: #ccc;
  width: 100%;
  height: 160px;
  padding: 20px 0 30px;
  font-weight: 300;
  position: relative;
  left: 0;
  bottom: 0;
}

footer .footer-box {
  max-width: 1480px;
  margin: 0 auto;
  width: 100%;
}

.terms-link {
  border-bottom: 1px solid #666;
  padding-bottom: 14px;
  display: flex;
}
.terms-link li {
  position: relative;
  margin-right: 65px;
  font-size: 18px;
  cursor: pointer;
}
.terms-link li.fw700 {
  font-weight: 700;
}
.terms-link li::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 155px;
  transform: translateY(-50%);
  width: 1px;
  height: 16px;
  background: #d9d9d9;
}
.terms-link li:last-child::after {
  display: none;
}
.terms-link li:first-child a {
  font-weight: 600;
}
.terms-link li:last-child {
  margin-right: 0;
}

.f-info {
  padding: 10px 0 16px;
}
.f-info li {
  display: inline-block;
  margin-right: 24px;
}
.f-info li span {
  margin-right: 10px;
}
.f-text .copy {
  font-size: 12px;
  color: #666;
}
</style>
