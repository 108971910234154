import { HttpInterface } from '@core/http';
import { WeatherApiEndpoint } from '@core/api-endpoint';
import { FetchWeatherResponse } from '@core/dto';

// export interface WeatherVilageFcst {
//   baseDate: string;
//   baseTime: string;
//   category: string;
//   fcstDate: string;
//   fcstTime: string;
//   fcstValue: string;
//   nx: number;
//   ny: number;
// }
//
// export type WeatherResponseKey =
//   | 'rnSt0Pm'
//   | 'rnSt1Am'
//   | 'rnSt1Pm'
//   | 'rnSt2Am'
//   | 'rnSt2Pm'
//   | 'rnSt3Am'
//   | 'rnSt3Pm'
//   | 'rnSt4Am'
//   | 'rnSt4Pm'
//   | 'rnSt5Am'
//   | 'rnSt5Pm'
//   | 'rnSt6Am'
//   | 'rnSt6Pm'
//   | 'rnSt7Am'
//   | 'rnSt7Pm'
//   | 'rnSt8'
//   | 'rnSt9'
//   | 'rnSt10'
//   | 'taMax0'
//   | 'taMax1'
//   | 'taMax2'
//   | 'taMax3'
//   | 'taMax4'
//   | 'taMax5'
//   | 'taMax6'
//   | 'taMax7'
//   | 'taMax8'
//   | 'taMax9'
//   | 'taMax10'
//   | 'taMin1'
//   | 'taMin2'
//   | 'taMin3'
//   | 'taMin4'
//   | 'taMin5'
//   | 'taMin6'
//   | 'taMin7'
//   | 'taMin8'
//   | 'taMin9'
//   | 'taMin10'
//   | 'wf0Pm'
//   | 'wf1Am'
//   | 'wf1Pm'
//   | 'wf2Am'
//   | 'wf2Pm'
//   | 'wf3Am'
//   | 'wf3Pm'
//   | 'wf4Am'
//   | 'wf4Pm'
//   | 'wf5Am'
//   | 'wf5Pm'
//   | 'wf6Am'
//   | 'wf6Pm'
//   | 'wf7Am'
//   | 'wf7Pm'
//   | 'wf8'
//   | 'wf9'
//   | 'wf10';
//
// export interface IWeatherResponse {
//   rnSt0Pm: number;
//   rnSt1Am: number;
//   rnSt1Pm: number;
//   rnSt2Am: number;
//   rnSt2Pm: number;
//   rnSt3Am: number;
//   rnSt3Pm: number;
//   rnSt4Am: number;
//   rnSt4Pm: number;
//   rnSt5Am: number;
//   rnSt5Pm: number;
//   rnSt6Am: number;
//   rnSt6Pm: number;
//   rnSt7Am: number;
//   rnSt7Pm: number;
//   rnSt8: number;
//   rnSt9: number;
//   rnSt10: number;
//   taMax0: number;
//   taMax1: number;
//   taMax2: number;
//   taMax3: number;
//   taMax4: number;
//   taMax5: number;
//   taMax6: number;
//   taMax7: number;
//   taMax8: number;
//   taMax9: number;
//   taMax10: number;
//   taMin1: number;
//   taMin2: number;
//   taMin3: number;
//   taMin4: number;
//   taMin5: number;
//   taMin6: number;
//   taMin7: number;
//   taMin8: number;
//   taMin9: number;
//   taMin10: number;
//   wf0Pm: string;
//   wf1Am: string;
//   wf1Pm: string;
//   wf2Am: string;
//   wf2Pm: string;
//   wf3Am: string;
//   wf3Pm: string;
//   wf4Am: string;
//   wf4Pm: string;
//   wf5Am: string;
//   wf5Pm: string;
//   wf6Am: string;
//   wf6Pm: string;
//   wf7Am: string;
//   wf7Pm: string;
//   wf8: string;
//   wf9: string;
//   wf10: string;
//   vilageFcstList: WeatherVilageFcst[];
// }
/**
 * 핵심비즈니스로직과 상관없어서 인터페이스 만들지 않음
 */
export class WeatherAdapter {
  http: HttpInterface;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
  }

  async fetchWeather(date: string): Promise<FetchWeatherResponse | {}> {
    try {
      const url = this.http.replacedUrl(WeatherApiEndpoint.fetchWeather, {
        date,
      });
      const response = await this.http.get<FetchWeatherResponse>(url);
      return response.data;
    } catch (error) {
      return {};
    }
  }
}
