import { computed, reactive, ref, watch } from 'vue';
import { AxiosAdapter, UserAdapter } from '@adapters';
import { SignUpUsecase } from '@core/useCases';
import { useCheckIdDuplicate } from '@composables';
import { useAlertStore, useUserStore } from '@stores';
import {
  DORMANT_ACCOUNT_CONVERSION_CODE,
  GENDER_CODE,
  LUNAR_CODE,
  STATUS_YN,
} from '@core/enums';
import {
  getBirthdayWithHyphen,
  isBirthday,
  isEmail,
  isMobileNumber,
  isPwd,
  isWebId,
} from '@core/utils';
import { SignUpPayload } from '@dto';

const genderOptions = [
  { value: GENDER_CODE.MALE, label: '남성' },
  { value: GENDER_CODE.FEMAIL, label: '여성' },
];
const smsAgreeOptions = [
  { value: STATUS_YN.YES, label: '네' },
  { value: STATUS_YN.NO, label: '아니오' },
];
const lunarOptions = [
  { value: LUNAR_CODE.SOLAR, label: '양력' },
  { value: LUNAR_CODE.LUNAR, label: '음력' },
];
const marketingAgreeOptions = [
  { value: true, label: '이메일수신 동의' },
  { value: false, label: '이메일수신 미동의' },
];
const personalInfoExpiryOptions = [
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT, label: '탈퇴 시까지' },
  { value: DORMANT_ACCOUNT_CONVERSION_CODE.YEAR, label: '1년 후 파기' },
];

export const useSignUp = () => {
  // ================================================================= 이하 회원가입시 약관동의 관련 코드
  const userStore = useUserStore();
  const allAgreeCheck = ref(false);
  const usageAgreeCheck = ref(false);
  // const privatePolicyCheck = ref(false);
  const privateAgreeCheck = ref(false);
  const marketingAgreeCheck = ref(userStore.marketingAgree);

  const agrees = reactive([
    {
      id: 'usageAgreeCheck',
      title: '이용약관',
      value: usageAgreeCheck,
      required: true,
      // visible: false,
      // content: usageAgree,
    },
    // {
    //   id: 'privatePolicyCheck',
    //   title: '개인정보보호정책',
    //   value: privatePolicyCheck,
    //   required: true,
    //   // visible: false,
    //   // content: privatePolicy,
    // },
    {
      id: 'privateAgreeCheck',
      title: '개인정보수집동의',
      value: privateAgreeCheck,
      required: true,
      // visible: false,
      // content: personalInfoConsent,
    },
    {
      id: 'marketingAgreeCheck',
      title: '마케팅 및 광고 활용 동의',
      value: marketingAgreeCheck,
      required: false,
      // visible: false,
      // content: advertisementConsent,
    },
  ]);

  const handleChangeAllCheck = (checked: Event | boolean) => {
    const isChecked =
      typeof checked === 'boolean' ? checked : allAgreeCheck.value;
    allAgreeCheck.value =
      usageAgreeCheck.value =
      privateAgreeCheck.value =
      marketingAgreeCheck.value =
        isChecked;
  };

  const isRequiredAgreeAllTrue = computed(() => {
    return (
      usageAgreeCheck.value &&
      // privatePolicyCheck.value &&
      privateAgreeCheck.value
    );
  });

  watch([usageAgreeCheck, privateAgreeCheck, marketingAgreeCheck], () => {
    allAgreeCheck.value =
      usageAgreeCheck.value &&
      privateAgreeCheck.value &&
      marketingAgreeCheck.value;
  });

  // ================================================================= 이하 회원가입시 정보입력 관련 코드
  const axiosAdapter = new AxiosAdapter();
  const userAdapter = new UserAdapter(axiosAdapter);
  const { handleCheckIdDuplicate: checkIdDuplicate } = useCheckIdDuplicate();
  const alertStore = useAlertStore();

  const webPwdConfirm = ref('');
  const minLength = 4;
  const maxLength = 12;
  const userInfo: SignUpPayload = reactive({
    memberName: userStore.memberName,
    hpNo: userStore.hpNo,
    webId: '',
    webPwd: '',
    birthDay: '',
    sexCode: GENDER_CODE.MALE,
    lunarCode: LUNAR_CODE.SOLAR,
    areaCode: '',
    sigunCode: '',
    email: '',
    emailRecptnAgre: true,
    smsRecptnDiv: userStore.isPrivacyUseAgreement
      ? STATUS_YN.YES
      : STATUS_YN.NO,
    marktInfoAgreFlag: userStore.marketingAgree,
    drmncyCnvrsCode: DORMANT_ACCOUNT_CONVERSION_CODE.UNTIL_OUT,
    homePostNo: '',
    homeAddr1: '',
    homeAddr2: '',
  });
  const isAvailableId = ref(false);
  const isPasswordMatch = computed(() => {
    return userInfo.webPwd === webPwdConfirm.value;
  });

  const handleCheckIdDuplicate = async () => {
    if (userInfo.webId === '') {
      await alertStore.showAlertDialog('아이디를 입력해주세요');
      return;
    }
    if (!isWebId(userInfo.webId, minLength, maxLength)) {
      await alertStore.showAlertDialog(
        `아이디는 ${minLength}자 이상 ${maxLength}자 이하로 입력해주세요`
      );
      return;
    }
    isAvailableId.value = await checkIdDuplicate(userInfo.webId);
    if (isAvailableId.value) {
      await alertStore.showAlertDialog('사용 가능한 아이디입니다.');
    }
  };

  const signUp = async () => {
    try {
      const signUpUsecase = new SignUpUsecase(userAdapter);
      await signUpUsecase.execute({
        ...userInfo,
        birthDay: getBirthdayWithHyphen(userInfo.birthDay),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validateSignUpForm = async (
    isVerifyComplete: boolean
  ): Promise<boolean> => {
    if (!isWebId(userInfo.webId, minLength, maxLength)) {
      await alertStore.showAlertDialog(
        `아이디는 영문이나 영문+숫자로 ${minLength}~${maxLength}자 이내로 입력해주세요.`
      );
      return false;
    }
    if (!isAvailableId.value) {
      await alertStore.showAlertDialog('아이디 중복확인을 해주세요.');
      return false;
    }
    if (!userInfo.webPwd) {
      await alertStore.showAlertDialog('비밀번호가 입력되지 않았습니다');
      return false;
    }
    if (!isPwd({ pwd: userInfo.webPwd, type: 'B', min: 8, max: 12 })) {
      await alertStore.showAlertDialog(
        '비밀번호는 영어/숫자/특수문자를 조합하여 8~12자 이내로 입력해주세요.'
      );
      return false;
    }
    if (!webPwdConfirm.value) {
      await alertStore.showAlertDialog('비밀번호 확인을 입력해 주세요');
      return false;
    }
    if (!isPasswordMatch.value) {
      await alertStore.showAlertDialog('비밀번호가 일치하지 않습니다.');
      return false;
    }
    if (!userInfo.memberName) {
      await alertStore.showAlertDialog('이름을 입력해주세요.');
      return false;
    }
    if (!isBirthday(userInfo.birthDay)) {
      await alertStore.showAlertDialog('생년월일을 확인해주세요.');
      return false;
    }
    if (userInfo.email && !isEmail(userInfo.email)) {
      await alertStore.showAlertDialog('이메일을 확인해주세요.');
      return false;
    }
    // if (!userInfo.sexCode) {
    //   await alertStore.showAlertDialog('성별을 선택하여주세요');
    //   return false;
    // }
    if (!isMobileNumber(userInfo.hpNo)) {
      await alertStore.showAlertDialog('휴대폰 번호를 확인해주세요.');
      return false;
    }
    if (!isVerifyComplete) {
      await alertStore.showAlertDialog('본인인증을 진행해주세요.');
      return false;
    }
    if (!userInfo.homePostNo || !userInfo.homeAddr1) {
      await alertStore.showAlertDialog('주소를 입력해주세요.');
      return false;
    }
    return true;
  };

  return {
    // 약관동의 return
    agrees,
    isRequiredAgreeAllTrue,
    handleChangeAllCheck,

    // 회원가입 약관동의 return
    allAgreeCheck,
    usageAgreeCheck,
    // privatePolicyCheck,
    privateAgreeCheck,
    marketingAgreeCheck,

    // 회원가입 폼 return
    userInfo,
    webPwdConfirm,
    genderOptions,
    smsAgreeOptions,
    lunarOptions,
    marketingAgreeOptions,
    personalInfoExpiryOptions,
    isAvailableId,
    isPasswordMatch,
    validateSignUpForm,
    signUp,
    handleCheckIdDuplicate,
  };
};
