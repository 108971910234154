import { UserAdapter } from '@core/adapters';

export class FetchUserDetailUseCase {
  constructor(private readonly userAdapter: UserAdapter) {
    this.userAdapter = userAdapter;
  }
  public async execute(): Promise<{ name: string; phone: string }> {
    return await this.userAdapter.fetchUserDetail();
  }
}
