import { ROUTE_NAME } from '@constants';

const useInformation = {
  path: '/use-information',
  redirect: '/use-information/guide',
  name: ROUTE_NAME.UseInformation,
  meta: {
    breadcrumb: true,
    breadcrumbName: '이용안내',
  },
  children: [
    {
      name: ROUTE_NAME.Guide,
      path: 'guide',
      component: () => import('@views/use-information/Guide.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '이용방법',
      },
    },
    {
      name: ROUTE_NAME.FeeInformation,
      path: 'fee-information',
      alias: 'fee-information',
      component: () => import('@views/use-information/FeeInformation.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '요금안내',
      },
    },
    {
      name: ROUTE_NAME.RuleInformation,
      path: 'rule-information',
      alias: 'rule-information',
      component: () => import('@views/use-information/RuleInformation.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '로컬룰',
      },
    },
  ],
};
export default useInformation;
