<template>
  <div class="weather" @click="handleGoWeather">
    <!--<span class="today">{{ now }}</span>-->
    <span class="flex gap-x-8 items-center">
      <span>덕양구 벽제동</span>
      <!--<span class="now2">{{ now2 }}</span>-->
      <!--<span class="day-of-week">{{ dayOfWeek }}</span>-->
      <span class="temperature">{{ weather?.t1h }}&deg;</span>

      <img
        v-if="weatherImg"
        alt="날씨 이미지"
        :src="weatherImgUrl"
        @click="handleGoWeather"
      />
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useWeather } from '@composables';

const { weather, weatherImg } = useWeather();
const weatherUrl = import.meta.env.VITE_APP_WEATHER_URL;
const weatherImgUrl = computed(() => `/weather/${weatherImg.value}.svg`);

const handleGoWeather = () => {
  window.open(weatherUrl, '_blank', 'noopener, noreferrer');
};
</script>

<style scoped lang="scss">
.weather {
  @apply text-white pl-16 mb-10 leading-normal hidden cursor-pointer;
  @apply desktop:block;
  span {
    @apply align-baseline;
  }
  .today {
    @apply fs-[34] fw-[500] block;
  }
  .now2,
  .day-of-week,
  .temperature {
    @apply fs-[15] fw-[500];
  }
}
</style>
