<template>
  <div class="login-wrap">
    <img src="@assets/img/logo-color.svg" alt="헤더로고_컬러" />

    <div class="input-area">
      <span>아이디</span>
      <AInput id="idHpNo" class="login-inp" v-model="idHpNo" />
      <span>비밀번호</span>
      <AInput
        id="password"
        class="login-inp"
        v-model="password"
        type="password"
        @keydown.enter="handleSignIn"
      />
    </div>

    <div class="info">
      <ACheckbox v-model="saveIdCheck" id="saveIdCheck" class=""
        >아이디 저장</ACheckbox
      >
      <button @click="handleMoveFindId">아이디 / 비밀번호 찾기</button>
    </div>

    <div class="common-btn">
      <AButton @click="handleSignIn" class="btn on">로그인</AButton>
      <AButton @click="handleMoveSignup" class="btn">회원가입</AButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { AButton, ACheckbox, AInput } from '@atoms';
import { useSignIn } from '@composables';
import { ROUTE_NAME } from '@constants';
import { useRouter, useRoute } from 'vue-router';
import { useCommonStore } from '@stores';

const { push } = useRouter();
const route = useRoute();
const commonStore = useCommonStore();
const { idHpNo, password, saveIdCheck, handleSignIn: signIn } = useSignIn();
const emits = defineEmits(['signIn']);

const handleMoveFindId = async () => {
  // const isNowFindIdPage = route.name === ROUTE_NAME.FindId;
  // if (isNowFindIdPage) {
  commonStore.setSigninOpen(false);
  //   return;
  // }
  await push({ name: ROUTE_NAME.FindId });
};

const handleMoveSignup = async () => {
  const isNowSignupPage = route.name === ROUTE_NAME.Signup;
  if (isNowSignupPage) {
    commonStore.setSigninOpen(false);
    return;
  }
  await push({ name: ROUTE_NAME.Signup });
};

const handleSignIn = async () => {
  try {
    if (await signIn()) {
      emits('signIn', true);
      await push({ name: ROUTE_NAME.Main });
    }
  } catch (error) {
    console.error(error);
  }
};
</script>

<style scoped lang="scss">
.login-wrap {
  width: 400px;
  border: 1px solid #ccc;
  border-radius: 16px;
  background: #fff;
  padding: 32px 30px;
  text-align: center;
}

.login-wrap img {
  margin: 0 auto 27px;
}

.input-area .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-area span {
  text-align: left;
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
}
.input-area .login-inp {
  margin-bottom: 16px;
  font-size: 18px;
}
.input-area .login-inp:last-child {
  margin-bottom: 0;
}

.info span {
  cursor: pointer;
}

.common-btn .btn {
  background: #fff;
  width: 100%;
  display: block;
  border-radius: 60px;
  border: 1px solid #eb7617;
  color: #eb7617;
  font-weight: 700;
  padding: 11px;
  margin-bottom: 8px;
}
.common-btn .btn.on {
  background: #eb7617;
  color: #fff;
  border: 1px solid #eb7617;
}

.info {
  display: flex;
  justify-content: space-between;
  margin: 8px 0 39px;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  color: #222;
}

/*모바일*/
@media (min-width: 320px) and (max-width: 1279px) {
  .login-wrap {
    width: 310px;
    background: #fff;
    padding: 32px 14px;
    border-radius: 16px;
    border: 1px solid #ccc;
    text-align: center;
  }
}
</style>
