import { ReservationAdapter } from '@core/adapters';
import {
  FetchReservationListPayload,
  FetchReservationListResponse,
} from '@core/dto';

export class FetchReservationListUseCase {
  constructor(private readonly reservationAdapter: ReservationAdapter) {
    this.reservationAdapter = reservationAdapter;
  }
  public async execute(
    payload: FetchReservationListPayload
  ): Promise<FetchReservationListResponse> {
    return await this.reservationAdapter.fetchReservationList(payload);
  }
}
