import { ROUTE_NAME } from '@constants';
const clubIntroduction = {
  path: '/club-introduction',
  redirect: '/club-introduction/intro',
  meta: {
    breadcrumb: true,
    breadcrumbName: '클럽소개',
  },
  name: ROUTE_NAME.ClubIntroduction,
  children: [
    {
      name: ROUTE_NAME.ClubIntroduction,
      path: 'intro',
      component: () => import('@views/club-introduction/Intro.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '클럽소개',
      },
    },
    {
      name: ROUTE_NAME.Location,
      path: 'location',
      component: () => import('@views/club-introduction/Location.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '오시는길',
      },
    },
    {
      name: ROUTE_NAME.ClubCi,
      path: 'club-ci',
      component: () => import('@views/club-introduction/ClubCi.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: 'CI소개',
      },
    },
  ],
};

export default clubIntroduction;
