<template>
  <div class="quick-btn" v-if="isAllowedPage && commonStore.isMobile">
    <ul>
      <li class="on">
        <router-link :to="{ name: ROUTE_NAME.Reservation }">
          <img :src="ReservationIcon" alt="예약" />
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: ROUTE_NAME.ReservationStatus }">
          <img :src="ReservationCheckIcon" alt="예약확인" />
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';
import { RouteLocationNormalized, useRouter } from 'vue-router';
import { ROUTE_NAME } from '@constants';
import ReservationIcon from '@assets/img/mobile/quick_icon_rser.svg';
import ReservationCheckIcon from '@assets/img/mobile/quick_icon_check.svg';
import { useCommonStore } from '@stores';

const isAllowedPage = ref(false);
const commonStore = useCommonStore();
const { currentRoute } = useRouter();

const exceptRouteList = [
  ROUTE_NAME.Reservation,
  ROUTE_NAME.ReservationStatus,
  ROUTE_NAME.Signin,
  ROUTE_NAME.Signup,
  ROUTE_NAME.FindId,
  ROUTE_NAME.FindPassword,
  ROUTE_NAME.NewPasswordAccount,
  ROUTE_NAME.UserInfoModify,
  ROUTE_NAME.NoticeWrite,
  ROUTE_NAME.NoticeModify,
  ROUTE_NAME.BannerWrite,
  ROUTE_NAME.BannerModify,
];

watch(currentRoute, (to: RouteLocationNormalized) => {
  const isExceptRoute = exceptRouteList.includes(to.name as ROUTE_NAME);
  isAllowedPage.value = !isExceptRoute;
});
</script>

<style scoped lang="scss">
/* QUICK BTN */
.quick-btn {
  @apply fixed;
  z-index: 5;
  bottom: 5%;
  right: 5%;
}

.quick-btn li {
  @apply wi-[50] he-[50] flex items-center justify-center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #222222;
}

.quick-btn li.on {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #eb7617;
  margin-bottom: 8px;
}

.quick-btn li img {
  display: block;
  margin: 0 auto;
}
</style>
