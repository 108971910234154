import { defineStore, DefineStoreOptions } from 'pinia';
import { RouteMeta } from 'vue-router';
interface MetaStoreState {
  routeMeta: {
    backGround?: string;
    subTitle?: string;
    subTitleMobile?: string;
    title?: string;
    hasSectionTitle?: boolean;
  };
}
interface MetaStoreGetters {
  getMetaState: (state: MetaStoreState) => MetaStoreState['routeMeta'];
}

interface MetaStoreActions {
  changeMeta: (meta: RouteMeta) => void;
  updateMeta: (meta: RouteMeta) => void;
}

type metaStoreOptions = DefineStoreOptions<
  string,
  MetaStoreState,
  MetaStoreGetters,
  MetaStoreActions
>;

const metaStoreId = 'MetaStore';
const metaStoreOptions: metaStoreOptions = {
  id: metaStoreId,
  state: () => ({ routeMeta: {} }),
  getters: {
    getMetaState() {
      return this.routeMeta;
    },
  },
  actions: {
    changeMeta(object: RouteMeta) {
      this.routeMeta = object || null;
    },
    updateMeta(object: RouteMeta) {
      Object.assign(this.routeMeta, object);
    },
  },
};
export const useMetaStore = defineStore(metaStoreId, metaStoreOptions);
