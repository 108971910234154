import { WeatherAdapter } from '@core/adapters';

export class FetchWeatherUseCase {
  constructor(private readonly weatherAdapter: WeatherAdapter) {
    this.weatherAdapter = weatherAdapter;
  }
  async execute(date: string) {
    return await this.weatherAdapter.fetchWeather(date);
  }
}
