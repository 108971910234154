import { DeleteCompanionPayload } from '@core/dto';
import { ReservationAdapter } from '@core/adapters';
export class DeleteCompanionUseCase {
  constructor(private readonly reservationAdapter: ReservationAdapter) {
    this.reservationAdapter = reservationAdapter;
  }
  public async execute(payload: DeleteCompanionPayload): Promise<void> {
    return await this.reservationAdapter.deleteCompanion(payload);
  }
}
