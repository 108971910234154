// /**
//  * 프로젝트에서 사용되는 상태값만 선언하자,
//  * */
// interface UserData {
//   name: string;
//   id: string;
//   sexCode: string;
//   areaCode: string;
//   sigunCode: string;
//   birthDay: string;
//   lunarCode: string;
//   smsRecptnDiv: string;
//   drmncyCnvrsCode: string;
// }
// export class UserModel {
//   constructor({ id, name, tgMember }: UserData) {
//     this.name = name;
//     this.id = id;
//     this.sexCode = tgMember.sexCode;
//     this.areaCode = tgMember.tgMemberAddi.areaCode;
//     this.sigunCode = tgMember.tgMemberAddi.sigunCode;
//     this.drmncyCnvrsCode = tgMember.tgMemberAddi.drmncyCnvrsCode;
//     this.birthDay = tgMember.birthDay;
//     this.lunarCode = tgMember.lunarCode;
//     this.smsRecptnDiv = tgMember.smsRecptnDiv;
//   }
// }

/**
 * 프로젝트에서 사용되는 상태값만 선언하자,
 * */
interface UserData {
  id: string;
  name: string;
}
export class UserModel {
  id: string;
  name: string;
  constructor({ id, name }: UserData) {
    this.id = id;
    this.name = name;
  }
}
