import { ERROR_MESSAGES as CORE_ERROR_MESSAGES } from '@core/constants';

const ccName = import.meta.env.VITE_APP_CC_NAME;

export enum ERROR_MESSAGES_RESERVATION {
  UNOPENED = '오픈전입니다.',
  END = '마감되었습니다.',
  PAST = '마감되었습니다.',
  CLOSE = '휴장입니다.',
}

export const ERROR_MESSAGES: { [key: string]: string } = {
  ...CORE_ERROR_MESSAGES,
  INTERCEPTED_ID: `회원님은 홈페이지 사용이 제한된 상태입니다.<br />
홈페이지 사용을 원하시면<br /> ${ccName}로 연락주시기 바랍니다.`,
  FIND_ID_DUPLICATED_WEB_ID: `아이디가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
  NOT_EXIST_MEMBER: `입력하신 회원번호의 회원이 존재하지 않습니다.<br />${ccName}로 연락주시기 바랍니다.`,
  FIND_ID_DUPLICATED_MEMBER: `회원정보가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
  FIND_ID_DUPLICATED_MEMBERSHIP: `회원권정보가 2건 이상 조회되었습니다.<br />${ccName}로 연락주시기 바랍니다.`,
};

export enum ROUTE_NAME {
  Main = 'Main', // 메인
  NotFound = 'NotFound', // 페이지없음
  RepairHomepage = 'RepairHomepage', // 시스템점검
  // auth
  Signin = 'Signin', // 로그인
  SigninAdmin = 'SigninAdmin', // 관리자로그인
  SignupAgree = 'SignupAgree', // 회원가입약관동의
  SignupCertification = 'SignupCertification', // 회원가입인증
  Signup = 'Signup', // 회원가입
  SignupComplete = 'SignupComplete', // 회원가입완료
  UserInfoModify = 'UserInfoModify', // 회원정보수정
  MyInfo = 'MyInfo', //마이페이지
  FindId = 'FindId', // ID 찾기
  FindPassword = 'FindPassword', // PW 찾기
  NewPasswordAccount = 'NewPasswordAccount', // 신규비밀번호생성
  MyInfoModify = 'MyInfoModify', // 회원정보 수정

  //agree
  ServiceTerms = 'ServiceTerms', // 이용약관
  InternetTerms = 'InternetTerms', // 인터넷이용약관
  PrivacyTerms = 'PrivacyTerms', // 개인정보 처리방침
  PrivacyEmail = 'PrivacyEmail', // 이메일 무단수집거부
  VideoInformation = 'VideoInformation', //영상정보처리기기 운영관리방침

  //클럽소개 club-info
  Intro = 'Intro', // 클럽개요
  ClubIntroduction = 'ClubIntroduction', // 클럽소개
  ClubCi = 'ClubCi', // CI소개
  Greeting = 'Greeting', // 인사말
  CountryClubIntroduction = 'CountryClubIntroduction', // 컨트리클럽소개
  Location = 'Location', // 오시는길

  //이용안내 use-information
  UseInformation = 'UseInformation', // 이용안내
  Guide = 'Guide', // 이용방법
  FeeInformation = 'FeeInformation', // 요금안내
  ReservInformation = 'ReservInformation', // 예약안내
  RuleInformation = 'RuleInformation', // 로컬룰
  AgreeTerms = 'AgreeTerms', // 약관동의

  //코스소개 club-introduction
  CourseInformation = 'CourseInformation', // 코스소개
  CourseIntroduction = 'CourseIntroduction', // 코스소개
  CourseStrategy = 'CourseStrategy', // 코스공략

  // 예약 reservation
  ReservationService = 'ReservationService', // 예약서비스
  ReservationInformation = 'ReservationInformation', // 예약안내
  Reservation = 'Reservation', // 예약하기
  ReservationStatus = 'ReservationStatus', // 예약확인/취소
  ReservationPayment = 'ReservationPayment', // 예약결제

  // 부대시설 facility
  Facilities = 'Facilities', // 부대시설
  ClubHouse = 'ClubHouse', // 클럽하우스
  Restaurant = 'Restaurant', // 레스토랑
  LockerRoom = 'LockerRoom', // 로커룸
  StartHouse = 'StartHouse', // 스타트하우스
  Light = 'Light', // 라이트시설
  OndolRoom = 'OndolRoom', // 온돌룸

  // 커뮤니티 community
  Community = 'Community', // 커뮤니티
  Notice = 'Notice', // 공지사항
  NoticeView = 'NoticeView', // 공지사항 상세
  NoticeWrite = 'NoticeWrite', // 공지사항 작성
  NoticeModify = 'NoticeModify', // 공지사항 작성
  Banner = 'Banner', // 배너
  BannerWrite = 'BannerWrite', // 배너 작성
  BannerModify = 'BannerModify', // 배너 수정
  Archive = 'Archive', // 자료실
  ArchiveView = 'ArchiveView', // 자료실 상세
  ArchiveWrite = 'ArchiveWrite', // 자료실 작성
  ArchiveModify = 'ArchiveModify', // 자료실 작성

  //고객의 소리 voc
  Withdrawal = 'Withdrawal', // 회원탈퇴

  HoleInOne = 'HoleInOne', //홀인원
  HoleInOneView = 'HoleInOneView', //홀인원 상세
  HoleInOneWrite = 'HoleInOneWrite', //홀인원 작성
  HoleInOneModify = 'HoleInOneModify', //홀인원 수정
}
