import { ref, onMounted } from 'vue';
import { AxiosAdapter, ReservationAdapter } from '@adapters';
import {
  RequestCompanionUseCase,
  DeleteCompanionUseCase,
} from '@core/useCases';
import {
  DeleteCompanionPayload,
  RequestCompanionPayload,
  TgResveCmpn,
  TgResveMember,
  TgResveTime,
} from '@dto';
import { useReservationList } from '@composables';
import { GENDER_CODE } from '@core/enums';

const genderOptions = [
  { value: GENDER_CODE.MALE, label: '남성' },
  { value: GENDER_CODE.FEMAIL, label: '여성' },
];

export const useCompanion = (props: { reservationId: number }) => {
  const axiosAdapter = new AxiosAdapter();
  const reservationAdapter = new ReservationAdapter(axiosAdapter);
  const requestCompanionUseCase = new RequestCompanionUseCase(
    reservationAdapter
  );
  const deleteCompanionUseCase = new DeleteCompanionUseCase(reservationAdapter);

  const { fetchReservationDetail } = useReservationList();
  const companionName = ref<string>('');
  const companionSexCode = ref<string>(GENDER_CODE.MALE);
  const companionPhone = ref<string>('');
  const companionList = ref<TgResveCmpn[] | []>([]);
  const companionNameConfirm = ref<string>('');
  const companionSexCodeConfirm = ref<string>('');
  const companionPhoneConfirm = ref<string>('');

  const visitCount = ref<number>(0);
  const reservationId = ref<number>(props.reservationId);
  const reserveMember = ref<TgResveMember>();
  const reserveTime = ref<TgResveTime>();

  const fetchCompanionList = async () => {
    const { visitCnt, tgResveCmpns } = await fetchReservationDetail({
      reservationId: reservationId.value,
    });
    visitCount.value = visitCnt;
    _fillCompanionList(tgResveCmpns, visitCnt);
  };

  const _fillCompanionList = (
    companionListFromApi: TgResveCmpn[],
    visitCnt: number
  ) => {
    const emptyCompanionCount = visitCnt - companionListFromApi.length;
    if (emptyCompanionCount > 0) {
      companionList.value = companionListFromApi.concat(
        Array(emptyCompanionCount).fill({ cmpnName: '동반자' })
      );
    } else {
      companionList.value = companionListFromApi;
    }
  };

  const requestCompanion = async (payload: RequestCompanionPayload) => {
    await requestCompanionUseCase.execute(payload);
  };

  const deleteCompanion = async (payload: DeleteCompanionPayload) => {
    await deleteCompanionUseCase.execute(payload);
  };

  onMounted(async () => {
    const { visitCnt, tgResveCmpns, tgResveMember, tgResveTime } =
      await fetchReservationDetail({
        reservationId: reservationId.value,
      });
    reserveMember.value = tgResveMember;
    reserveTime.value = tgResveTime;
    _fillCompanionList(tgResveCmpns, visitCnt);
  });

  return {
    companionName,
    companionSexCode,
    companionPhone,
    companionNameConfirm,
    companionSexCodeConfirm,
    companionPhoneConfirm,
    genderOptions,
    companionList,
    reserveMember,
    reserveTime,
    requestCompanion,
    deleteCompanion,
    fetchCompanionList,
  };
};
