<template>
  <teleport to="body">
    <div v-if="isVisible" :class="backdropClasses">
      <div class="modal-container" :class="$attrs.class" :style="props.style">
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const props = defineProps({
  direction: {
    type: String,
    default: 'center',
  },
  style: {
    type: Object,
    default: () => ({}),
  },
});

const isVisible = ref(false);

const backdropClasses = computed(() => {
  return [
    'backdrop',
    props.direction === 'center'
      ? 'items-center justify-center'
      : 'justify-end',
  ];
});

// 부모 컴포넌트에서 접근하기 위한 함수.
const open = () => {
  isVisible.value = true;
};

const close = () => {
  isVisible.value = false;
};

// defineExpose 해줘야 부모함수에서 접근 가능
defineExpose({ open, close });
</script>

<style lang="scss" scoped>
.backdrop {
  @apply flex fixed bg-black bg-opacity-80 w-screen h-screen left-0 top-0 items-center justify-center;
  z-index: 150;
}
.modal-container {
  @apply min-h-[30%] flex justify-center items-center;
  //@apply absolute origin-[-50%_-50%] bg-white px-16 py-28;
  @apply mobile:wi-[320] mobile:rounded-br-none;
  //@apply desktop:w-[410px];
  //background: transparent;
  //width: 100%;
}
//.isResveMobile {
//  @apply mobile:w-4/5 mobile:bottom-0 mobile:h-auto mobile:overflow-y-scroll mobile:pb-80;
//}
</style>
