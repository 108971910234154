import { UserPort } from '@core/ports';
import { UserModel } from '@core/models';
import { HttpInterface } from '@core/http';
import { UserApiEndpoint } from '@core/api-endpoint';
import {
  CheckIdDuplicatePayload,
  FetchUserInfoResponse,
  SignUpMemberPayload,
  SignUpPayload,
  SignUpResponse,
  SignUpOAuthPayload,
  SignUpOAuthResponse,
  UpdateUserInfoPayload,
  ValidExistMemberPayload,
  WithdrawalPayload,
} from '@core/dto';
import { UserResponseAdapter } from '@core/adapters';
import { useUserStore } from '@stores';

// class
export class UserAdapter
  implements
    UserPort<
      UserModel,
      | SignUpPayload
      | SignUpMemberPayload
      | SignUpOAuthPayload
      | CheckIdDuplicatePayload
      | WithdrawalPayload
      | ValidExistMemberPayload
    >
{
  http: HttpInterface;
  constructor(httpInstance: HttpInterface) {
    this.http = httpInstance;
  }
  async signUp(payload: SignUpPayload): Promise<UserModel> {
    const response = await this.http.post<SignUpResponse>(
      UserApiEndpoint.signUp,
      payload
    );
    const userInfo = UserResponseAdapter.signUpAdapt(response.data);
    const userStore = useUserStore();
    userStore.setUserInfo(userInfo);
    return new UserModel(userInfo);
  }
  async signUpMember(payload: SignUpMemberPayload): Promise<any> {
    const response = await this.http.post<any>(
      UserApiEndpoint.signUpMember,
      payload
    );
    return response.data;
  }
  async fetchUserDetail(): Promise<{ name: string; phone: string }> {
    const response = await this.http.get<{ name: string; phone: string }>(
      UserApiEndpoint.fetchUserDetail
    );
    return response.data;
  }
  async fetchUserInfo(): Promise<FetchUserInfoResponse> {
    const response = await this.http.get<FetchUserInfoResponse>(
      UserApiEndpoint.fetchUserInfo
    );
    return response.data;
  }
  async updateUserInfo(payload: UpdateUserInfoPayload): Promise<any> {
    return await this.http.patch<any>(UserApiEndpoint.updateUserInfo, payload);
  }
  async checkIdDuplicate(payload: string): Promise<boolean | null> {
    const response = await this.http.get<boolean | null>(
      UserApiEndpoint.checkUserIdDuplicate,
      {
        id: payload,
      }
    );
    return response.data;
  }
  async withdrawal(payload?: WithdrawalPayload): Promise<void> {
    const config = payload ? { data: payload } : {};
    await this.http.delete<void>(UserApiEndpoint.withdrawal, config);
  }
  async validExistMember(payload: ValidExistMemberPayload): Promise<boolean> {
    const response = await this.http.post<boolean>(
      UserApiEndpoint.validExistMember,
      payload
    );
    return response.data;
  }
  async signUpOAuth(payload: SignUpOAuthPayload): Promise<SignUpOAuthResponse> {
    const response = await this.http.post<SignUpOAuthResponse>(
      UserApiEndpoint.signUpOAuth,
      payload
    );
    return response.data;
  }
}
