import { ReservationAdapter } from '@core/adapters';
import {
  FetchReservationDetailPayload,
  FetchReservationDetailResponse,
} from '@core/dto';

export class FetchReservationDetailUseCase {
  constructor(private readonly reservationAdapter: ReservationAdapter) {
    this.reservationAdapter = reservationAdapter;
  }
  public async execute(
    payload: FetchReservationDetailPayload
  ): Promise<FetchReservationDetailResponse> {
    return await this.reservationAdapter.fetchReservationDetail(payload);
  }
}
