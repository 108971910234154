import { RouteRecordRaw } from 'vue-router';
import { ROUTE_NAME } from '@constants';
import auth from '@infra/router/module/auth';
import clubIntroduction from '@infra/router/module/club-introduction';
import courseIntroduction from '@infra/router/module/course-introduction';
import reservation from '@infra/router/module/reservation';
import facility from '@infra/router/module/facility';
import useInformation from '@infra/router/module/use-information';
import community from '@infra/router/module/community';

const routes: RouteRecordRaw[] = [
  {
    name: 'PaySmartro',
    path: '/smartro',
    component: () => import('@views/PaySmartro.vue'),
    meta: {
      // layout: 'ClearLayout',
    },
  },
  {
    name: 'Test',
    path: '/test',
    component: () => import('@views/Test.vue'),
    meta: {
      breadcrumb: false,
    },
  },
  {
    name: ROUTE_NAME.Main,
    path: '/',
    component: () => import('@views/Index.vue'),
    meta: {
      hasSectionTitle: false,
    },
  },
  {
    name: ROUTE_NAME.RepairHomepage,
    path: '/repairs',
    component: () => import('@views/RepairHomepage.vue'),
    meta: {
      layout: 'ClearLayout',
    },
  },
  {
    name: ROUTE_NAME.NotFound,
    path: '/:pathMatch(.*)*',
    component: () => import('@views/NotFound.vue'),
    meta: {
      layout: 'ClearLayout',
      hasSectionTitle: false,
    },
  },
  auth,
  clubIntroduction,
  courseIntroduction,
  reservation,
  facility,
  community,
  useInformation,
];

export default routes;
