<template>
  <div class="terms">
    <h1>[선택] 개인정보 수집 및 이용동의</h1>
    <div class="scroll">
      <table class="terms-table">
        <colgroup>
          <col width="33.3%" span="3" />
        </colgroup>
        <tbody>
          <tr>
            <th>수집 및 이용목적</th>
            <th>수집하는<span>개인정보 항목</span></th>
            <th>보유 및 이용기간</th>
          </tr>
          <tr>
            <td>광고성 문자메세지 전송(할인 이벤트등)</td>
            <td>휴대전화번호,<span>이메일</span></td>
            <td>가입 시 선택</td>
          </tr>
          <tr>
            <td>새로운 상품 안내마케팅(전화,이메일)</td>
            <td>휴대전화번호,<span>이메일</span></td>
            <td>가입 시 선택</td>
          </tr>
          <tr>
            <td>공지사항,클럽소식등 정보제공</td>
            <td>휴대전화번호,<span>이메일</span></td>
            <td>가입 시 선택</td>
          </tr>
        </tbody>
      </table>
      ※ 동의를 거부하시는 경우에도 예약서비스는 이용하실수 있습니다.<br /><br />
      본 동의는 서비스 제공을 위한 개인정보 수집/이용에 대한 동의로서, 고객님은
      개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 본 동의를 거부할
      경우 받는 별도의 불이익은 없습니다.’ - 개인정보 수집/이용 및 광고성 정보
      수신 동의를 “미동의” (으)로 선택하셔도 개인정보 보호를 위한 정보 안내 수신
      동의/철회 등의 메시지나 메일은 발송 될 수 있습니다. - 법령에 따른
      개인정보의 수집/이용, 계약의 이행/편의증진을 위한 개인정보 취급위탁 및
      개인정보 취급과 관련된 일반 사항은 당사의 개인정보 취급 방침에 따릅니다. -
      고객이 본 수신동의를 철회하고자 할 경우 당사의 개인정보 취급
      책임자/담당자를 통해 수신동의 철회요청을 할 수 있습니다.
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss">
/*팝업*/
.terms {
  width: 1104px;
  height: 580px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #ccc;
  padding: 56px 62px;
}
.terms h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 40px;
}
.terms .scroll {
  overflow-y: auto;
  height: 445px;
  font-size: 1rem;
  font-weight: 300;
  padding-right: 5px;
  line-height: 1.13rem;
  padding-bottom: 56px;
}

/*팝업 테이블*/
.terms-table {
  width: 100%;
  border-top: 2px solid #222;
  border-bottom: 1px double #222;
  text-align: center;
  font-size: 1.13rem;
  margin-bottom: 1rem;
}
.terms-table tbody th {
  font-size: 1rem;
  font-weight: 700;
  color: #666;
  background: #f9f9f9;
  border-right: 1px solid #ccc;
  padding: 18px 0;
}
.terms-table tbody th:last-child {
  border-right: 0;
}
.terms-table tbody td {
  padding: 21px 0;
  font-weight: 300;
  border: 1px solid #ccc;
  line-height: 18px;
}
.terms-table tbody td:first-child {
  border-left: 0;
}
.terms-table tbody td:last-child {
  border-right: 0;
}

/*팝업 스크롤바*/
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #ccc;
}
::-webkit-scrollbar-thumb {
  background-color: #eb7617;
}

/*팝업 닫기 버튼*/
.modal-footer button {
  background: rgba(255, 255, 255, 0.11);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
.modal-footer button img {
  width: 24px;
  height: 24px;
}

/*모바일*/
@media (min-width: 320px) and (max-width: 1279px) {
  .terms {
    width: 96% !important;
    margin: 0 auto;
    padding: 32px 16px !important;
    height: 480px !important;
  }
  .terms h1 {
    font-size: 1.13rem;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .terms .scroll {
    overflow-y: auto;
    height: 400px;
    font-size: 0.81rem;
    font-weight: 300;
    padding-right: 5px;
    line-height: 1.13rem;
    padding-bottom: 32px;
  }

  /*테이블*/
  .terms-table {
    font-size: 0.81rem;
  }
  .terms-table tbody th {
    font-size: 0.81rem;
    padding: 4px 0;
  }
  .terms-table tbody th span {
    display: block;
  }
  .terms-table tbody td {
    padding: 4px 0;
  }
  .terms-table tbody td span {
    display: block;
  }

  /*팝업 닫기 버튼*/
  .modal-footer button {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
  .modal-footer button img {
    width: 16px;
    height: 16px;
  }
}
</style>
