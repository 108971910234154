/**
 * api response를 프론트에맞는 값으로 필요한 것만 리턴하는 어댑터
 */
import { FindIdResponse, SignInResponse } from '@core/dto';

export class AuthResponseAdapter {
  static signInAdapt(response: SignInResponse): any {
    return {
      accessToken: response.accessToken,
      isPwdChangeNeeded: response.isPwdChangeNeeded,
      pwdChangeCycleMonth: response.pwdChangeCycleMonth,
      tgMemberships: response.tgMemberships.map((membership: any) => {
        return {
          membershipId: membership.membershipId,
          linkMemberId: membership.linkMemberId,
          memberNo: membership.memberNo,
          memberDiv: membership.memberDiv,
          reprsntFlag: membership.reprsntFlag,
          memberGrade: membership.memberGrade,
          entryOrder: membership.entryOrder,
          cprDiv: membership.cprDiv,
          memberId: membership.memberId,
          renameDiv: membership.renameDiv,
          outFlag: membership.outFlag,
          membershipStatus: membership.membershipStatus,
          saleNo: membership.saleNo,
          fitBenFlag: membership.fitBenFlag,
          issueAmt: membership.issueAmt,
          acqsDate: membership.acqsDate,
          acqsAmt: membership.acqsAmt,
          acqstxAmt: membership.acqstxAmt,
          payAmt: membership.payAmt,
          unpayAmt: membership.unpayAmt,
        };
      }),
    };
  }
  static findIdAdapt(response: FindIdResponse): FindIdResponse {
    return {
      webId: response.webId,
      memberId: response.memberId,
      intrcpFlag: response.intrcpFlag,
      tempIssuFlag: response.tempIssuFlag,
      loginDt: response.loginDt,
      loginKind: response.loginKind,
      snsEntryFlag: response.snsEntryFlag,
      opersysm: response.opersysm,
      brwsr: response.brwsr,
      connectIp: response.connectIp,
      connectCnt: response.connectCnt,
      insertDt: response.insertDt,
      tgMember: response.tgMember,
      name: response.name,
      id: response.id,
    };
  }
}
