import { UserAdapter } from '@core/adapters';

export class CheckIdDuplicateUsecase {
  constructor(private readonly userAdapter: UserAdapter) {
    this.userAdapter = userAdapter;
  }

  async execute(id: string): Promise<boolean> {
    const response = await this.userAdapter.checkIdDuplicate(id);
    return response === null;
  }
}
