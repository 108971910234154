<template>
  <button
    type="button"
    @click="onClick"
    :class="buttonClass"
    :disabled="!!props.disabled"
  >
    <slot>Default Label</slot>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'md',
    validator: (value: string) => ['sm', 'md', 'lg'].includes(value),
  },
  disabled: {
    type: [Boolean, String],
  },
});
const emits = defineEmits(['click']);

const buttonClass = computed(() => {
  const disabled = props.disabled ? 'button-disabled' : '';
  return `button button-${props.size} ${disabled}`;
});

const onClick = (event: MouseEvent) => {
  emits('click', event);
};
</script>

<style scoped lang="scss">
.button {
  &-sm {
    @apply pd-[4];
  }
  &-md {
    @apply pd-[8];
  }
  &-lg {
    @apply pd-[12];
  }
  &-disabled {
    @apply bg-gray-200 text-white cursor-not-allowed;
  }
}
</style>
