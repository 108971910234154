import { ref } from 'vue';
import { useUserStore, useCommonStore, useAlertStore } from '@stores';
import { AxiosAdapter, AuthAdapter } from '@adapters';
import { SignInUseCase, SignInAdminUseCase } from '@core/useCases';
interface SigninPayload {
  isAdmin?: boolean;
}
export const useSignIn = () => {
  const userStore = useUserStore();
  const commonStore = useCommonStore();
  const alertStore = useAlertStore();

  const axiosAdapter = new AxiosAdapter();
  const authAdapter = new AuthAdapter(axiosAdapter);

  const idHpNo = ref<string>(commonStore.getLocalStorage('saveId') || '');
  const password = ref<string>('');
  const saveIdCheck = ref(!!idHpNo.value);
  const error = ref<string | null>(null);
  const loading = ref<boolean>(false);

  const _signIn = async () => {
    const signInUseCase = new SignInUseCase(authAdapter);
    return signInUseCase.execute({
      idHpNo: idHpNo.value,
      password: password.value,
    });
  };

  const _signInAdmin = async () => {
    const signInAdminUseCase = new SignInAdminUseCase(authAdapter);
    return signInAdminUseCase.execute({
      email: idHpNo.value,
      password: password.value,
    });
  };

  const _handleSaveId = () => {
    saveIdCheck.value
      ? commonStore.setStorage('localStorage', 'saveId', idHpNo.value)
      : commonStore.removeStorage('localStorage', 'saveId');
  };

  const _showWelcomeMessage = async () => {
    const userName = userStore.username;
    await alertStore.showAlertDialog(`${userName} 회원님 환영합니다.`);
  };

  const handleSignIn = async (payload: SigninPayload = {}) => {
    loading.value = true;
    error.value = null;
    const { isAdmin } = payload;

    if (!idHpNo.value) {
      await alertStore.showAlertDialog('아이디를 입력해주세요.');
      return;
    }
    if (!password.value) {
      await alertStore.showAlertDialog('비밀번호를 입력해주세요.');
      return;
    }
    try {
      await (isAdmin ? _signInAdmin() : _signIn());
      if (commonStore.isSignIn) {
        _handleSaveId();
        await _showWelcomeMessage();
        userStore.username && userStore.setMemberName(userStore.username);
        return true;
      }
      return;
    } catch (err: any) {
      error.value = err.message || '로그인에 실패했습니다.';
    } finally {
      loading.value = false;
    }
  };

  return {
    idHpNo,
    password,
    saveIdCheck,
    handleSignIn,
    error,
    loading,
  };
};
