import { ROUTE_NAME } from '@constants';
const facility = {
  path: '/facility',
  redirect: '/facility/clubhouse',
  name: ROUTE_NAME.Facilities,
  meta: {
    breadcrumb: true,
    breadcrumbName: '시설안내',
  },
  children: [
    {
      name: ROUTE_NAME.ClubHouse,
      path: 'clubhouse',
      alias: 'clubhouse',
      component: () => import('@views/facility/ClubHouse.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '클럽하우스',
      },
    },
    {
      name: ROUTE_NAME.StartHouse,
      path: 'starthouse',
      alias: 'starthouse',
      component: () => import('@views/facility/StartHouse.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '스타트하우스',
      },
    },
    {
      name: ROUTE_NAME.OndolRoom,
      path: 'ondolroom',
      alias: 'ondolroom',
      component: () => import('@views/facility/OndolRoom.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '토방',
      },
    },
    {
      name: ROUTE_NAME.Light,
      path: 'light',
      alias: 'light',
      component: () => import('@views/facility/Light.vue'),
      meta: {
        isReady: false,
        breadcrumb: true,
        breadcrumbName: '라이트시설',
      },
    },
  ],
};
export default facility;
