import { FetchUserInfoResponse, SignUpResponse } from '@core/dto';

export class UserResponseAdapter {
  static signUpAdapt(response: SignUpResponse): SignUpResponse {
    return {
      webId: response.webId,
      memberId: response.memberId,
      intrcpFlag: response.intrcpFlag,
      tempIssuFlag: response.tempIssuFlag,
      loginKind: response.loginKind,
      snsEntryFlag: response.snsEntryFlag,
      opersysm: response.opersysm,
      brwsr: response.opersysm,
      connectIp: response.connectIp,
      connectCnt: response.connectCnt,
      insertDt: response.insertDt,
      tgMember: response.tgMember,
      name: response.name,
      id: response.id,
    };
  }
  static fetchUserInfoAdapt(
    response: FetchUserInfoResponse
  ): FetchUserInfoResponse {
    return {
      webId: response.webId,
      memberId: response.memberId,
      intrcpFlag: response.intrcpFlag,
      tempIssuFlag: response.tempIssuFlag,
      loginDt: response.loginDt,
      loginKind: response.loginKind,
      snsEntryFlag: response.snsEntryFlag,
      opersysm: response.opersysm,
      brwsr: response.opersysm,
      connectIp: response.connectIp,
      connectCnt: response.connectCnt,
      insertDt: response.insertDt,
      tgMember: response.tgMember,
      name: response.name,
      id: response.id,
    };
  }
}
