import { CommonAdapter } from '@core/adapters';
import { FetchSystemInfoResponse } from '@core/dto';
export class FetchCommonTotalUseCase {
  constructor(private readonly commonAdapter: CommonAdapter) {
    this.commonAdapter = commonAdapter;
  }
  public async execute(): Promise<FetchSystemInfoResponse | null> {
    return await this.commonAdapter.fetchSystemInfo();
  }
}
