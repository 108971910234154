import { RequestCompanionPayload } from '@core/dto';
import { ReservationAdapter } from '@core/adapters';
export class RequestCompanionUseCase {
  constructor(private readonly reservationAdapter: ReservationAdapter) {
    this.reservationAdapter = reservationAdapter;
  }
  public async execute(payload: RequestCompanionPayload): Promise<void> {
    return await this.reservationAdapter.requestCompanion(payload);
  }
}
