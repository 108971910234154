<template>
  <div class="terms">
    <h1>서비스 이용약관</h1>
    <div class="scroll">
      올림픽 컨트리클럽 서비스 이용약관<br /><br /><br />

      제 1 장 총칙<br /><br /><br />

      제 1 조 (목적)<br />
      이 약관은 올림픽 컨트리클럽에서 제공하는 인터넷 서비스를 이용함에 있어
      회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.<br /><br />

      제 2 조 (약관의 효력 및 변경)<br />
      ① 이 약관은 서비스를 통하여 이를 공지함으로써 효력이 발생됩니다.<br />
      ② 회사는 사정상 중요한 사유가 발생될 경우 사전 고지 없이 이 약관의 내용을
      변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지함으로써 효력이
      발생됩니다.<br />
      ③ 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수 있으며,
      변경된 약관의 효력 발생일 이후에도 서비스를 계속 사용할 경우 약관의 변경
      사항에 동의한 것으로 간주됩니다.<br /><br />

      제 3 조 (약관 외 준칙)<br />
      본 약관에 명시되지 아니한 사항에 대해서는 전기통신기본법, 전기통신사업법,
      정보통신망 이용촉진등에 관한 법률 및 기타 관련 법령의 규정에 따릅니다.<br /><br />

      제 4 조 (용어의 정의)<br />
      이 약관에서 사용하는 주요한 용어의 정의는 다음과 같습니다.<br />
      1.회원 : 회사와 서비스 이용 계약을 체결하고 이용자 아이디(ID)를 부여 받은
      자를 말합니다.<br />
      2.아이디 : 회원의 식별과 회원의 서비스 이용을 위하여 회원이 선정하고
      회사가 승인하는 문자나 숫자 혹은 그 조합을 말합니다(이하 "ID"라
      합니다).<br />
      3.비밀번호 : 회원이 부여 받은 ID와 일치된 회원임을 확인하고, 회원 자신의
      비밀을 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.<br /><br /><br />

      제 2장 서비스 이용 계약<br /><br /><br />

      제 5 조 (이용 계약의 성립)<br />
      ① 서비스 가입 신청 시 본 약관을 읽고 "동의함" 버튼을 클릭하면 이 약관에
      동의하는 것으로 간주됩니다.<br />
      ② 이용계약은 서비스 이용희망자의 이용약관 동의 후 이용 신청에 대하여
      회사가 승낙함으로써 성립합니다.<br />
      ③ 카카오톡 알림톡 시행에 관한 내용 “올림픽 콜로세움사이트 및 올림픽 CC
      사이트"은 회원가입, 주문안내, 배송안내 등 비광고성 정보를 카카오톡
      알림톡으로 알려드리며, 만약 알림톡 수신이 불가능하거나 수신 차단하신
      경우에는 일반 문자메시지로 보내드립니다.<br />
      카카오톡 알림톡을 통해 안내되는 정보를 와이파이가 아닌 이동통신망으로
      이용할 경우, 알림톡 수신 중 데이터 요금이 발생할 수 있습니다.<br />
      카카오톡을 통해 발송되는 알림톡 수신을 원치 않으실 경우 반드시 알림톡을
      차단하여 주시기 바랍니다.<br /><br />

      제 6 조 (이용 신청)<br />
      회원으로 가입하여 서비스를 이용하기를 희망하는 자는 회사가 정한 소정
      양식에 따라 요청하는 개인인적사항을 제공하여 이용신청을 합니다.<br /><br />

      제 7 조 (이용 신청의 승낙)<br />
      ① 회사는 제 6 조에 따른 이용신청에 대하여 특별한 사정이 없는 한 접수
      순서대로 이용 신청을 승낙합니다.<br />
      ② 회사는 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수 있고,
      그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.<br />
      1.서비스 관련 설비에 여유가 없는 경우<br />
      2.기술상 지장이 있는 경우<br />
      3.기타 회사의 사정상 필요하다고 인정되는 경우<br />
      ③ 회사는 다음 각 호에 해당하는 사항을 인지하는 경우 동 이용계약 신청을
      승낙하지 아니할 수 있습니다.<br />
      1.본인의 실명으로 신청하지 않은 경우<br />
      2.다른 사람의 명의를 사용하여 신청한 경우<br />
      3.이용 신청 시 필요 사항을 허위로 기재하여 신청한 경우<br />
      4.사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우<br />
      5.기타 회사가 정한 이용 신청 요건이 미비 된 경우<br />
      ④ 제 2 항 또는 3 항에 의하여 이용신청의 승낙을 유보하거나 승낙하지
      아니하는 경우, 회사는 이를 이용신청자에게 알려야 합니다.<br />
      다만, 회사의 귀책사유 없이 이용신청자에게 통지할 수 없는 경우는 예외로
      합니다.<br /><br />

      제 8 조 (개인정보의 보호)<br />
      ① 회사는 회원의 개인정보를 보호하고 존중합니다.<br />
      ② 회사는 이용 신청 시 회원이 제공하는 정보, 커뮤니티 활동, 각종 이벤트
      참가를 위하여 회원이 제공하는 정보, 기타 서비스 이용 과정에서 수집되는
      정보 등을 통하여 회원에 관한 정보를 수집하며,<br />
      회원의 개인정보는 본 이용계약의 이행과 본 이용계약상의 서비스 제공을 위한
      목적으로 사용됩니다.<br />
      ③ 회사는 서비스 제공과 관련하여 취득한 회원의 신상정보를 본인의 승낙 없이
      제3자에게 누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수
      없습니다.<br />
      다만, 다음의 각 호에 해당하는 경우에는 그러하지 아니합니다.<br />
      1.정보통신서비스의 제공에 따른 요금 정산을 위하여 필요한 경우<br />
      2.통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을
      알아볼 수 없는 형태로 가공하여 제공하는 경우<br />
      3.관계 법령에 의하여 수사상 목적으로 정해진 절차와 방법에 따라 관계기관의
      요구가 있는 경우<br />
      4.다른 법률에 특별한 규정이 있는 경우<br />
      5.정보통신윤리위원회의 요청이 있는 경우<br />
      ④ 회사는 다음과 같은 경우에는 이용자의 동의 하에 개인정보를 제3자에게
      제공할 수 있습니다.<br />
      이러한 경우에도 개인정보의 제3자 제공은 이용자의 동의 하에서만 이루어지며
      개인정보가 제공되는 것을 원하지 않는 경우에는, 특정 서비스를 이용하지
      않거나 특정한 형 태의 이벤트에 참여하지 않으면 됩니다.<br />
      1.올림픽 콜로세움사이트 및 올림픽 CC 사이트내에서 벌어지는 각종 이벤트
      행사에 참여한 회원의 개인정보가 해당 이벤트의 주최자에게 제공될 수
      있습니다.<br />
      ⑤ 제 3 항의 범위 내에서 회사는 업무와 관련하여 회원 전체 또는 일부의
      개인정보에 관한 집합적인 통계 자료를 작성하여 이를 사용할 수 있고,
      서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다.<br />
      이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록
      사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.<br />
      ⑥ 회원이 가입 시 제공한 정보는 회원 가입 시점부터 해지 완료 후 30일까지
      보관할 수 있습니다.<br />
      단, 회원권 구입등의 서비스를 이용한 회원의 개인정보와 거래 내역은 만약에
      있을 수 있는 환불, 신용카드 매출취소 등의 경우를 대비하여 90일간
      보관됩니다.<br />
      상법 등 관련 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 예외로
      합니다.<br /><br />

      제 9 조 (계약 사항의 변경)<br />
      ① 회원은 개인정보관리를 통해 언제든지 본인의 개인정보를 열람하고 수정할 수
      있습니다.<br />
      ② 회원은 이용신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 해야
      하며 회원정보를 변경하지 아니하여 발생되는 문제의 책임은 회원에게
      있습니다.<br /><br /><br />

      제 3 장 계약 당사자의 의무<br /><br /><br />

      제 10 조 (회사의 의무)<br />
      ① 회사는 특별한 사정이 없는 한 회원이 서비스 이용을 신청한 날에 서비스를
      이용할 수 있도록 합니다.<br />
      ② 회사는 이 약관에서 정한 바에 따라 계속적이고 안정적인 서비스의 제공을
      위하여 지속적으로 노력하며, 설비에 장애가 생기거나 멸실 된 때에는 지체
      없이 이를 수리 복구하여야 합니다.<br />
      다만, 천재지변, 비상사태 또는 그 밖에 부득이한 경우에는 그 서비스를 일시
      중단하거나 중지할 수 있습니다.<br />
      ③ 회사는 회원으로부터 소정의 절차에 의해 제기되는 의견이나 불만이
      정당하다고 인정할 경우에는 적절한 절차를 거처 처리하여야 합니다.<br />
      처리 시 일정 기간이 소요될 경우 회원에게 그 사유와 처리 일정을 알려주어야
      합니다.<br />
      ④ 회사는 회원의 프라이버시 보호와 관련하여 제 8 조 항에 제시된 내용을
      지킵니다.<br />
      ⑤ 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용고객과의 계약
      관련 절차 및 내용 등에 있어 이용고객에게 편의를 제공하도록 노력합니다.<br /><br />

      제 11 조 (회원의 의무)<br />
      ① 회원은 이 약관에서 규정하는 사항과 이용안내 또는 공지사항 등을 통하여
      회사가 공지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를
      하여서는 안됩니다.<br />
      ② 회원의 ID와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게
      부여된 ID와 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 모든 결과에
      대한 책임은 회원에게 있습니다.<br />
      ③ 회원은 자신의 ID나 비밀번호가 부정하게 사용되었다는 사실을 발견한
      경우에는 즉시 회사에 신고하여야 하며, 신고를 하지 않아 발생하는 모든
      결과에 대한 책임은 회원에게 있습니다.<br />
      ④ 회원은 회사의 사전승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며,
      그 영업활동의 결과와 회원이 약관에 위반한 영업활동을 하여 발생한 결과에
      대하여 회사는 책임을 지지 않습니다.<br />
      회원은 이와 같은 영업활동으로 회사가 손해를 입은 경우 회원은 회사에 대하여
      손해배상의무를 집니다.<br />
      ⑤ 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용
      계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수
      없습니다.<br />
      ⑥ 회원은 서비스 이용과 관련하여 다음 각 호에 해당되는 행위를 하여서는
      안됩니다.<br />
      1.다른 회원의 ID와 비밀번호, 주민등록번호 등을 도용하는 행위<br />
      2.본 서비스를 통하여 얻은 정보를 회사의 사전승낙 없이 회원의 이용 이외
      목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는
      행위<br />
      3.타인의 특허, 상표, 영업비밀, 저작권 기타 지적재산권을 침해하는 내용을
      게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위<br />
      4.공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형
      등을 전송, 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위<br />
      5.모욕적이거나 위협적이어서 타인의 프라이버시를 침해할 수 있는 내용을
      전송, 게시, 전자메일 또는 기타의 방법으로 타인에게 유포하는 행위<br />
      6.범죄와 결부된다고 객관적으로 판단되는 행위<br />
      7.회사의 승인을 받지 않고 다른 사용자의 개인정보를 수집 또는 저장하는
      행위<br />
      8.기타 관계법령에 위배되는 행위<br /><br /><br />

      제 4장 서비스 이용<br /><br /><br />

      제 12 조 (서비스 이용 범위)<br />
      회원은 회사를 통한 가입 시 발급된 ID로 올림픽 콜로세움 및 올림픽 CC 사이트
      모두를 이용할 수 있습니다.<br /><br />

      제 13 조 (정보의 제공)<br />
      회사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보를
      공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다.<br />
      회사는 회원에게 보다 나은 서비스 혜택 제공을 위해 다양한 전달 방법(전화,
      안내문, 메일..등)을 통해 서비스 관련 정보를 제공할 수 있습니다.<br />
      단, 회사는 회원이 서비스 혜택 정보 제공을 원치 않는다는 의사를 밝히는 경우
      정보 제공 대상에서 해당 회원을 제외하여야 하며, 대상에서 제외되어 서비스
      정보를 제공받지 못해 불 이익이 발생하더라도 이에 대해서는 회사가 책임지지
      않습니다.<br /><br />

      제 14 조 (요금 및 유료정보 등)<br />
      회사가 제공하는 서비스는 기본적으로 무료입니다.<br /><br />

      제 15 조 (회원의 게시물)<br />
      회사는 회원이 게시하거나 등록하는 서비스내의 내용물이 다음 각 호에
      해당한다고 판단되 는 경우에 사전통지 없이 삭제할 수 있습니다.<br />
      1.다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인
      경우<br />
      2.공공질서 및 미풍양속에 위반되는 내용인 경우<br />
      3.범죄적 행위에 결부된다고 인정되는 내용일 경우<br />
      4.회사의 저작권, 제 3 자의 저작권 등 기타 권리를 침해하는 내용인 경우<br />
      5.회사에서 규정한 게시기간이나 용량을 초과한 경우<br />
      6.회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트를
      링크하는 경우<br />
      7.게시판의 성격에 부합하지 않는 게시물의 경우<br />
      8.기타 관계법령에 위반된다고 판단되는 경우<br /><br />

      제 16 조 (게시물의 저작권)<br />
      서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.<br />
      1.게시물에 대한 권리와 책임은 게시자에게 있으며 회사는 게시자의 동의
      없이는 이를 서비스 내 게재 이외에 영리적 목적으로 사용할 수 없습니다.<br />
      단, 비영리적인 경우에는 그러하 지 아니하며 또한 회사는 서비스 내의
      게재권을 갖습니다.<br />
      2.회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에
      게재된 자료를 상업적으로 사용할 수 없습니다.<br /><br />

      제 17 조 (서비스 이용시간)<br />
      ① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴
      1일 24시간 가능함을 원칙으로 합니다.<br />
      다만 정기 점검 등의 필요로 회사가 정한 날이나 시간은 그러하지 않습니다.<br />
      ② 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로
      정할 수 있습니다. 이 경우 사전에 공지를 통해 그 내용을 알립니다.<br /><br />

      제 18 조 (서비스 이용 책임)<br />
      ① 회원은 회사에서 권한 있는 사원이 서명한 명시적인 서면에 구체적으로
      허용한 경우를 제외하고는 서비스를 이용하여 상품을 판매하는 영업활동을 할
      수 없으며 특히 해킹, 돈벌이 광고, 음란 사이트 등을 통한 상업행위, 상용S/W
      불법배포 등을 할 수 없습니다.<br />
      이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적
      조치등에 관해서는 회사가 책임을 지지 않습니다.<br />
      ② 개인정보의 위탁 처리 “올림픽 콜로세움사이트 및 올림픽 CC 사이트”은
      서비스 향상을 위해 관계법령에 따라 회원의 동의를 얻거나 관련 사항을 공개
      또는 고지 후 회원의 개인정보를 외부에 위탁하여 처리하고 있습니다.<br />
      “올림픽 콜로세움사이트 및 올림픽 CC 사이트”의 개인정보처리 수탁자와 그
      업무의 내용은 다음과 같습니다.<br />
      - 수탁자 : (주)루나소프트<br />
      - 위탁 업무 내용 : 카카오톡 알림톡(정보성메세지) 발송 업무<br /><br />

      제 19 조 (서비스 제공의 중지 등)<br />
      ① 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.<br />
      1.서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br />
      2.전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을
      경우<br />
      3.기타 불가항력적 사유가 있는 경우<br />
      ② 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주
      등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를
      제한하거나 중지할 수 있습니다.<br />
      ③ 회사는 제 1 항 및 2항의 규정에 의하여 서비스의 이용을 제한하거나 중지한
      때에는 그 사유 및 제한기간 등을 지체 없이 회원에게 알려야 합니다.<br /><br /><br />

      제 5 장 계약 해지 및 이용 제한<br /><br /><br />

      제 20 조 (계약 해지 및 이용 제한)<br />
      ① 회원이 이용 계약을 해지하고자 하는 경우에는 회원 본인이 온라인을 통해
      회원탈퇴를 회사에 신청하여야 합니다.<br />
      ② 회사는 회원이 다음 각 호에 해당하는 행위를 하였을 경우 사전통지 없이
      이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수
      있습니다.<br />
      1.타인의 개인정보, ID 및 비밀번호를 도용한 경우<br />
      2. 가입한 이름이 실명이 아닌 경우<br />
      3.타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우<br />
      4.회사, 다른 회원 또는 제 3자의 지적재산권을 침해하는 경우<br />
      5.공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우<br />
      6.회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는
      실행하는 경우<br />
      7.서비스 운영을 고의로 방해한 경우<br />
      8.서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성
      정보를 전송하는 경우<br />
      9.정보통신설비의 오작동이나 정보의 파괴를 유발시키는 컴퓨터 바이러스
      프로그램 등을 유포하는 경우<br />
      10.정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과
      관련하여 선거관리위원회의 유권해석을 받은 경우 11.회사의 서비스를 이용하여
      얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로
      이용하는 경우<br />
      12.회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란 사이트
      링크하는 경우<br />
      13.본 약관을 포함하여 기타 회사가 정한 이용 조건에 위반한 경우<br /><br /><br />

      제 6 장 손해배상 및 기타 사항<br /><br /><br />

      제 21 조 (손해배상)<br />
      회사는 이용 요금이 무료인 서비스 이용과 관련하여 회원에게 발생한 어떠한
      손해에 관하여도 책임을 지지 않습니다.<br /><br />

      제 22 조 (면책조항)<br />
      ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수
      없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br />
      ② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지
      않습니다.<br />
      ③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것이나 서비스를
      통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.<br />
      ④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등
      내용에 관하여는 책임을 지지 않습니다.<br />
      ⑤ 회사는 서비스 이용과 관련하여 가입자에게 발생한 손해 가운데 가입자의
      고의, 과실에 의한 손해에 대하여 책임을 지지 않습니다.<br /><br />

      제 23 조 (관할법원)<br />
      서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를
      관할하는 법원을 전속 관할법원으로 합니다.<br /><br />

      [부칙] 1.(시행일) 이 약관은 2017년 12월 29일부터 시행합니다.<br />
    </div>
  </div>
</template>
<script setup lang="ts"></script>

<style scoped lang="scss">
/*팝업*/
.terms {
  width: 1104px;
  height: 580px;
  background: #fff;
  border-radius: 16px;
  border: 1px solid #ccc;
  padding: 56px 62px;
}
.terms h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 40px;
}
.terms .scroll {
  overflow-y: auto;
  height: 445px;
  font-size: 1rem;
  font-weight: 300;
  padding-right: 5px;
  line-height: 1.13rem;
  padding-bottom: 56px;
}

/*팝업 테이블*/
.terms-table {
  width: 100%;
  border-top: 2px solid #222;
  border-bottom: 1px double #222;
  text-align: center;
  font-size: 1.13rem;
  margin-bottom: 1rem;
}
.terms-table tbody th {
  font-size: 1rem;
  font-weight: 700;
  color: #666;
  background: #f9f9f9;
  border-right: 1px solid #ccc;
  padding: 18px 0;
}
.terms-table tbody th:last-child {
  border-right: 0;
}
.terms-table tbody td {
  padding: 21px 0;
  font-weight: 300;
  border: 1px solid #ccc;
  line-height: 18px;
}
.terms-table tbody td:first-child {
  border-left: 0;
}
.terms-table tbody td:last-child {
  border-right: 0;
}

/*팝업 스크롤바*/
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background-color: #ccc;
}
::-webkit-scrollbar-thumb {
  background-color: #eb7617;
}

/*팝업 닫기 버튼*/
.modal-footer button {
  background: rgba(255, 255, 255, 0.11);
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}
.modal-footer button img {
  width: 24px;
  height: 24px;
}

/*모바일*/
@media (min-width: 320px) and (max-width: 1279px) {
  .terms {
    width: 96% !important;
    margin: 0 auto;
    padding: 32px 16px !important;
    height: 480px !important;
  }
  .terms h1 {
    font-size: 1.13rem;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .terms .scroll {
    overflow-y: auto;
    height: 400px;
    font-size: 0.81rem;
    font-weight: 300;
    padding-right: 5px;
    line-height: 1.13rem;
    padding-bottom: 32px;
  }

  /*테이블*/
  .terms-table {
    font-size: 0.81rem;
  }
  .terms-table tbody th {
    font-size: 0.81rem;
    padding: 4px 0;
  }
  .terms-table tbody th span {
    display: block;
  }
  .terms-table tbody td {
    padding: 4px 0;
  }
  .terms-table tbody td span {
    display: block;
  }

  /*팝업 닫기 버튼*/
  .modal-footer button {
    background: rgba(255, 255, 255, 0.11);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }
  .modal-footer button img {
    width: 16px;
    height: 16px;
  }
}
</style>
