import { TgMembership } from '@core/dto';

interface AuthInterface {
  accessToken?: string;
  isAuthenticated?: boolean;
  tgMemberships?: TgMembership[] | [];
  // membershipId?: string;
}

export class AuthModel {
  accessToken: string;
  isAuthenticated: boolean;
  tgMemberships: TgMembership[] | [];
  // membershipId: string;

  constructor({
    accessToken,
    isAuthenticated,
    tgMemberships = [],
  }: AuthInterface) {
    this.accessToken = accessToken ?? '';
    this.isAuthenticated = isAuthenticated ?? false;
    this.tgMemberships = tgMemberships;
    // this.membershipId = this.getMembershipId();
  }
  static createFromResponse(response: any): AuthModel {
    const { accessToken, tgMemberships } = response;
    return new AuthModel({
      accessToken,
      isAuthenticated: true,
      tgMemberships,
    });
  }

  static createEmpty(): AuthModel {
    return new AuthModel({ accessToken: '', isAuthenticated: false });
  }

  getMembershipId(): string {
    if (this.tgMemberships.length === 0) return '';
    return this.tgMemberships[0].membershipId;
  }

  getAccessToken(): string {
    return this.accessToken;
  }

  signIn(accessToken: string): void {
    this.accessToken = accessToken;
  }
  signOut(): void {
    this.accessToken = '';
  }
}
