<template>
  <MBaseDialog :isVisible="isVisible">
    <template #content>
      <div class="alert-content" v-html="message"></div>
    </template>
    <template #actions>
      <AButton @click="hideAlert" class="btn-chk">확인</AButton>
    </template>
  </MBaseDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AButton } from '@atoms';
import { useAlertStore } from '@stores';
import { MBaseDialog } from '@molecules';

const alertStore = useAlertStore();

const isVisible = computed(() => alertStore.isAlertVisible);
const message = computed(() => alertStore.message);

const hideAlert = () => {
  alertStore.hideAlertDialog();
};
</script>

<style scoped lang="scss">
.alert-content {
  @apply text-center;
}
.btn-chk {
  background: #eb7617;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
  width: 45%;
  padding: 12px 0;
  border-radius: 60px;
}
</style>
