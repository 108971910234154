import { defineStore } from 'pinia';

interface AlertState {
  isAlertVisible: boolean;
  isConfirmVisible: boolean;
  message: string;
  // isConfirm: boolean;
  // isVaildate: boolean;
  resolve: ((value: boolean) => void) | null;
  reject: ((value: boolean) => void) | null;
}
export const useAlertStore = defineStore('modal', {
  state: (): AlertState => ({
    isAlertVisible: false,
    isConfirmVisible: false,
    message: '',
    // isConfirm: false,
    // isVaildate: false,
    resolve: null,
    reject: null,
  }),
  actions: {
    showAlertDialog(message: string): Promise<boolean> {
      this.message = message;
      this.isAlertVisible = true;
      // this.isVaildate = false;
      // this.isConfirm = false;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    showConfirmDialog(message: string): Promise<boolean> {
      this.message = message;
      this.isConfirmVisible = true;
      // this.isConfirm = true;
      // this.isVaildate = false;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    // showAlertDialog(message: string): Promise<boolean> {
    //   return new Promise((resolve) => {
    //     this.message = message;
    //     this.isAlertVisible = true;
    //     this.isVaildate = true;
    //     this.isConfirm = false;
    //     this.resolve = resolve;
    //   });
    // },
    // confirm(): void {
    //   if (this.isConfirm || this.isVaildate) {
    //     this.resolve && this.resolve(true);
    //   }
    //   this.isAlertVisible = false;
    // },
    hideAlertDialog(): void {
      this.isAlertVisible = false;
      if (this.resolve) {
        this.resolve(true);
        this.resolve = null;
      }
    },
    confirm(action: boolean): void {
      this.isConfirmVisible = false;
      if (this.resolve) {
        this.resolve(action);
        this.resolve = null;
      }
    },
    cancel(): void {
      this.confirm(false);
    },
  },
});
