<template>
  <MBaseDialog :isVisible="isVisible">
    <template #content>
      <div class="confirm-content" v-html="message"></div>
    </template>
    <template #actions>
      <div class="button-wrapper">
        <AButton @click="handleCancel">취소</AButton>
        <AButton @click="handleConfirm">확인</AButton>
      </div>
    </template>
  </MBaseDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { AButton } from '@atoms';
import { MBaseDialog } from '@molecules';
import { useAlertStore } from '@stores';

const alertStore = useAlertStore();

const isVisible = computed(() => alertStore.isConfirmVisible);
const message = computed(() => alertStore.message);

const handleConfirm = () => {
  alertStore.confirm(true);
};

const handleCancel = () => {
  alertStore.cancel();
};
</script>

<style scoped lang="scss">
.confirm-content {
  @apply text-center;
}
.button-wrapper {
  @apply flex justify-center gap-20 items-center mt-10;
  button {
    &:first-child {
      color: #ccc;
    }
    &:last-child {
      background: #eb7617;
      color: #fff;
      padding: 10px 20px;
    }
  }
}
</style>
